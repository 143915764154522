import React, { useEffect, useState } from 'react'
import { X } from 'tabler-icons-react'
import _ from 'lodash'

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'
import { updateScore } from '../../../store/services/CustomerDetails.services'
import { fetchAllScore } from '../../../store/services/Scores.services'
import { scoreTypes } from '../../../config/constants'
import { isNumberKey, failiureAlert } from '../../../utils/index'

import styles from '../../styles/FormComponent.module.css'
import { useScrollBlock } from '../../../hooks/useScrollBlock'
import { reset } from '../../../store/slices/Criteria.slice'

const initialInputValues = {
  customerId: '',
  msisdn: '',
  scoreType: '',
  score: '',
  remarks: ''
}

type TProps = {
  customerData: any[],
  hideForm: () => void
}

function ChangeScore(props: TProps) {
  const [blockScroll, allowScroll] = useScrollBlock()

  const dispatch = useAppDispatch()

  const
    [inputErrors, setInputErrors] = useState(initialInputValues),
    [inputs, setInputs] = useState(initialInputValues)

  const
    customerDetails = useAppSelector((state) => state.customerDetails),
    scores = useAppSelector((state) => state.scores)

  useEffect(() => {
    dispatch(fetchAllScore({}))
  }, [])

  useEffect(() => {
    blockScroll()

    return () => {
      allowScroll()
    }
  }, [])

  useEffect(() => {
    if (customerDetails.updateScore.loading === 'failed') {
      failiureAlert(customerDetails.updateScore.error, () => {
        dispatch(reset('updateScore'))
      })
    }
  }, [customerDetails.updateScore.loading])

  useEffect(() => {
    const { customerData } = props

    if (customerData.length > 0) {
      const custData = _.find(customerData, (custData) => custData?.scoreType)

      const { msisdn, customerId } = custData

      let { scoreType } = custData

      if (scoreType === 'EXPERIAN') {
        scoreType = 'i-SCORE'
      }

      setInputs({
        ...inputs,
        msisdn,
        customerId,
        scoreType
      })
    }
  }, [])

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const handleSave = (type: string) => {
    let isValid = true
    let errors = {}

    errors = { ...initialInputValues }

    if (inputs.scoreType.length < 1) {
      errors = { ...errors, scoreType: 'Score Type is required' }
      isValid = false
    }

    if (inputs.score.length < 1) {
      errors = { ...errors, score: 'Score is required' }
      isValid = false
    }

    setInputErrors({ ...inputErrors, ...errors })

    if (isValid === true) {
      let postParams = {}

      postParams = {
        scoreType: inputs.scoreType,
        score: inputs.score,
        remarks: inputs.remarks
      }

      if (inputs.msisdn) {
        postParams = {
          ...postParams,
          msisdn: inputs.msisdn
        }
      } else if (inputs.customerId) {
        postParams = {
          ...postParams,
          customerId: inputs.customerId
        }
      }

      // temporarily patch i-SCORE to EXPERIAN

      if (inputs.scoreType === 'i-SCORE') {
        postParams = {
          ...postParams,
          scoreType: 'EXPERIAN'
        }
      }

      dispatch(updateScore(postParams))
    }
  }

  return (
    <div className={styles.form_wrapper}>
      <div className={styles.form_box}>
        <div className={styles.form_header}>
          <h2 className={styles.form_title}>Change Score</h2>
          <X size={22} onClick={() => props.hideForm()} />
        </div>
        <div className={styles.form}>
          <div className={styles.input_row}>
            {
              inputs.msisdn && <div className={styles.form_input}>
                <label htmlFor="customerId">MSISDN</label>
                <input readOnly type="text" value={inputs.msisdn} />
              </div>
            }
            {
              inputs.customerId && <div className={styles.form_input}>
                <label htmlFor="customerId">Customer ID</label>
                <input readOnly type="text" value={inputs.customerId} />
              </div>
            }
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="scoreType">Score Type</label>
              <select
                name="scoreType"
                onChange={(e) => handleInputChange(e)}
                value={inputs.scoreType}
              >
                <option value="">- select - </option>
                {scoreTypes.map((item) => {
                  return (
                    <option key={item.key} value={item.key}>
                      {item.value}
                    </option>
                  )
                })}
              </select>
              {inputErrors.scoreType ? (
                <span className={styles.input_error}>
                  {inputErrors.scoreType}
                </span>
              ) : null}
            </div>
            <div className={styles.form_input}>
              <label htmlFor="score">Score</label>
              {inputs.scoreType === 'DIGI' || inputs.scoreType === 'CELCOM' ? (
                <select
                  name="score"
                  id="score"
                  value={inputs.score}
                  onChange={(e) => handleInputChange(e)}
                >
                  <option value="">- select - </option>
                  {scores.getall.data.map((item: any) => {
                    return (
                      <option key={item.SK} value={item.label}>
                        {`${item.name} (${item.label})`}
                      </option>
                    )
                  })}
                </select>
              ) : (
                <input
                  type="text"
                  autoComplete="off"
                  name="score"
                  id="score"
                  onKeyPress={(e) => isNumberKey(e)}
                  value={inputs.score}
                  onChange={(e) => handleInputChange(e)}
                />
              )}
              {inputErrors.score ? (
                <span className={styles.input_error}>{inputErrors.score}</span>
              ) : null}
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="remarks">Remarks</label>
              <textarea
                autoComplete="off"
                name="remarks"
                id="remarks"
                rows={4}
                value={inputs.remarks}
                onChange={(e) => handleInputChange(e)}
              ></textarea>
              {inputErrors.customerId ? (
                <span className={styles.input_error}>
                  {inputErrors.customerId}
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.form_button}>
          <span className={styles.error_message}></span>
          <button type="button" onClick={() => handleSave('save')}>
            Update
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChangeScore
