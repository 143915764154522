import { useLocation } from 'react-router-dom'
export function useActiveMenu() {
  const location = useLocation()

  const check = (path) => {
    return location.pathname === path
  }

  return { check }
}
