const AwsConfig = {
  Auth: {
    userPoolId: process.env.REACT_APP_AUTH_POOL_ID,
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT_ID
  },
  oauth: {
    mandatorySignIn: true,
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    scope: process.env.REACT_APP_AUTH_SCOPES?.split('|'),
    redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT,
    redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT,
    responseType: 'code'
  }
}

export default AwsConfig
