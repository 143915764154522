import React, { useEffect, useState, useContext } from 'react'
import { Gauge } from 'tabler-icons-react'
import Swal from 'sweetalert2'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// } from 'chart.js'
// import { Line } from 'react-chartjs-2'

// import { labels, options } from '../../config/chart.config'
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'

import { groups as cognitoGroups } from '../../config/values'

import { reset } from '../../store/slices/CustomerDetails.slice'

import { AuthContext } from '../../App'

import Header from '../../components/Header/Header'
import History from '../../components/CustomerDetails/History/History'
import EligibleCombos from '../../components/CustomerDetails/EligibleCombos/EligibleCombos'
import Spinner from '../../components/Spinner/Spinner'
import ChangeScore from '../../components/CustomerDetails/ChangeScore/ChangeScore'

import {
  getCustomerEligibleCombos,
  getCustomerScoreDetails,
  getDigiHistory,
  getExperianHistory,
  getDigiScoreDetails,
  getFinalScoreDetails
} from '../../store/services/CustomerDetails.services'

import { successAlert, trimUsername } from '../../utils/index'

import styles from './CustomerDetails.module.css'

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// )

// const initChartData = {
//   labels,
//   datasets: [
//     {
//       label: 'DIGI',
//       data: [0],
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)'
//     },
//     {
//       label: 'EXPERIAN',
//       data: [0],
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)'
//     }
//   ]
// }

function CustomerDetails() {
  // const [data, setData] = useState(initChartData)

  const authentication = useContext(AuthContext)

  const
    dispatch = useAppDispatch(),
    navigate = useNavigate()

  const [showChangeScore, setShowChangeScore] = useState(false)

  const customerData = useAppSelector((state) => state.customerDetails)

  const
    [id, setId] = useState<any>(''),
    [experianScore, setExperianScore] = useState<any>(null),
    [digiScore, setDigiScore] = useState<any>(null),
    [history, setHistory] = useState<any>([]),
    [finalScoreColour, setFinalScoreColour] = useState<any>(null)

  const groups = authentication.user?.signInUserSession.accessToken.payload['cognito:groups'] || []

  const
    isAdmin = groups.includes(cognitoGroups.icsAdmin),
    isManualScoreUpdater = groups.includes(cognitoGroups.icsManualCreditScore)

  // const [previousDigiScore, setPreviousDigiScore] = useState<any>(null)
  // const [previousExperianScore, setPreviousExperianScore] = useState<any>(null)

  useEffect(() => {
    if (customerData.customer.loading === 'failed') {
      Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: customerData.customer.error || 'unknown'
      })

      navigate('/')
    } else if (customerData.customer.loading === 'completed') {
      dispatch(reset('eligibleCombos'))

      dispatch(reset('digiHistory'))
      dispatch(reset('expHistory'))

      dispatch(reset('updateScore'))

      dispatch(reset('digiScoreDetails'))
      dispatch(reset('finalScoreDetails'))

      getScores()
    } else if (customerData.customer.loading === 'idle') {
      navigate('/')
    }
  }, [customerData.customer.loading])

  useEffect(() => {
    const getCustomerEligibleCombosParam: any = {}

    if (digiScore) {
      if (digiScore.msisdn) {
        setId(digiScore.msisdn)

        getCustomerEligibleCombosParam.msisdn = digiScore.msisdn
      } else {
        setId(digiScore.customerId)

        getCustomerEligibleCombosParam.customerId = digiScore.customerId
      }

      dispatch(getDigiScoreDetails({ label: digiScore.score }))

      dispatch(getDigiHistory(encodeURIComponent(digiScore.SK)))

    }

    if (experianScore) {
      if (experianScore.msisdn) {
        setId(experianScore.msisdn)

        getCustomerEligibleCombosParam.msisdn = experianScore.msisdn
      } else {
        setId(experianScore.customerId)

        getCustomerEligibleCombosParam.customerId = experianScore.customerId
      }

      dispatch(getExperianHistory(encodeURIComponent(experianScore.SK)))

    }

    dispatch(getCustomerEligibleCombos(getCustomerEligibleCombosParam))

  }, [digiScore, experianScore])

  useEffect(() => {
    const historyData: any = []

    if (digiScore && customerData.digiHistory.loading === 'completed') {
      if (customerData.digiHistory.data.data.length > 0) {
        customerData.digiHistory.data.data.forEach((item: any) => {
          historyData.push(item)
        })
      }
    }

    if (experianScore && customerData.expHistory.loading === 'completed') {
      if (customerData.expHistory.data.data.length > 0) {
        customerData.expHistory.data.data.forEach((item: any) => {
          historyData.push(item)
        })
      }
    }

    if (historyData) {
      const sortedData = historyData.sort(
        (a: any, b: any) => +new Date(b.updatedDate) - +new Date(a.updatedDate)
      )

      setHistory(sortedData)
    }

  }, [customerData.digiHistory.data, customerData.expHistory.data])

  useEffect(() => {
    if (customerData.eligibleCombos.loading === 'completed') {
      const
        digiScoreLabel = customerData.digiScoreDetails.data?.label,
        finalScoreLabel = customerData.eligibleCombos.data?.finalScore

      if (digiScoreLabel !== finalScoreLabel) {
        dispatch(getFinalScoreDetails({ label: customerData.eligibleCombos.data?.finalScore }))
      } else {
        setFinalScoreColour(customerData.digiScoreDetails.data?.colour)
      }
    }
  }, [customerData.eligibleCombos.data])

  useEffect(() => {
    if (customerData.finalScoreDetails.loading === 'completed') {
      setFinalScoreColour(customerData.finalScoreDetails.data?.colour)
    }
  }, [customerData.finalScoreDetails.data])

  useEffect(() => {
    if (customerData.updateScore.loading === 'completed') {
      setShowChangeScore(false)

      successAlert('The score has been updated successfully.', () => {
        dispatch(reset('updateScore'))

        dispatch(getCustomerScoreDetails(id))
      })
    }
  }, [customerData.updateScore.loading])

  const getScores = () => {
    if (customerData.customer.data.length > 0) {
      // reset digiScore, experianScore, and history

      setDigiScore(null)
      setExperianScore(null)
      setHistory([])

      // get last digi and experian score is history if any

      customerData.customer.data.map((item: any) => {
        if (item.scoreType === 'DIGI' || item.scoreType === 'CELCOM' || item.scoreType === 'CD') {
          setDigiScore(item)
        }

        if (item.scoreType === 'EXPERIAN') {
          setExperianScore(item)
        }

        return true
      })

      // setData({
      //   ...initChartData,
      //   datasets: [
      //     {
      //       ...initChartData.datasets[0],
      //       data: [9, 6, 4, 2, 8, 7, 6, 5, 1, 4, 6]
      //     },
      //     {
      //       ...initChartData.datasets[1],
      //       data: [8, 5, 4, 3, 4, 6, 9, 8, 6, 3, 1]
      //     }
      //   ]
      // })
    }
  }

  const hideForm = () => {
    setShowChangeScore(false)
  }

  const renderTenureArpuCv = () => {
    const getValue = (key: string) => (digiScore && digiScore[key]) || '-'

    return (
      <div className={styles.tenurearpucf_container}>
        <div className={styles.statistics}>
          <span>Tenure</span>
          <h2>{getValue('tenure')}</h2>
        </div>
        <div className={styles.statistics}>
          <span>ARPU</span>
          <h2>{getValue('ARPU')}</h2>
        </div>
        <div className={styles.statistics}>
          <span>CF (CV)</span>
          <h2>{getValue('CV')}</h2>
        </div>
      </div>
    )
  }

  const digiScoreColour = customerData.digiScoreDetails?.loading === 'completed' ? customerData.digiScoreDetails?.data?.colour : null

  return (
    <div className="wrapper">
      <Header />
      {customerData.updateScore.loading === 'pending' ? <Spinner /> : null}
      {
        customerData.customer.loading === 'pending' ? <Spinner /> : (
          <div className={styles.page_container}>
            <div className={styles.container}>
              <div className={styles.details_container}>
                <div className={styles.details}>
                  <div className={styles.customer_details}>
                    <div className={styles.customer_name}>
                      <h2>
                        {id}
                      </h2>
                      {
                        digiScore && digiScore.subscriberId && isAdmin ? (
                          <p>Subscriber ID - <strong>{ digiScore.subscriberId }</strong></p>
                        ) : null
                      }
                    </div>
                    <div className={styles.changescore_btn}>
                      {
                        customerData.customer.data && (isManualScoreUpdater || isAdmin) ? (
                          <button
                            onClick={() => setShowChangeScore(true)}
                            type="submit"
                          >
                            Change Score
                          </button>
                        ) : null
                      }
                    </div>
                  </div>
                  <div className={styles.score_container}>
                    <div className={styles.digi_score}>
                      {digiScore ? (
                        <React.Fragment>
                          <div className={styles.score_card}>
                            <div className={styles.icon}>
                              <Gauge size={100} />
                            </div>
                            <div className={styles.desc}>
                              <div className='score_wrapper'>
                                <h1>{digiScore.score}</h1>
                                { digiScoreColour && (
                                  <div className='score_color' style={{ background: digiScoreColour }}/>
                                ) }
                              </div>
                              <h3>{digiScore.scoreType} SCORE</h3>
                              {/* <span>
                                Previous Score: <strong>C2</strong>
                              </span> */}
                            </div>
                          </div>
                          <div className={styles.addinfo_container}>
                            <div className={styles.statistics}>
                              <span>Last Updated</span>
                              <h2>{ digiScore.updatedDate}</h2>
                              <span className='date_info'>{moment(digiScore.updatedDate).fromNow()}</span>
                            </div>
                            <div className={styles.statistics}>
                              <span>Update Medium</span>
                              <h2>{digiScore.updateMedium}</h2>
                            </div>
                            <div className={styles.statistics}>
                              <span>Created Date</span>
                              <h2>{digiScore.createdDate}</h2>
                              <span className='date_info'>{moment(digiScore.createdDate).fromNow()}</span>
                            </div>
                            {
                              digiScore.updatedBy && digiScore.updateMedium === 'manual' && (
                                <div className={styles.statistics}>
                                  <span>Updated By</span>
                                  <h2>{trimUsername(digiScore.updatedBy)}</h2>
                                </div>
                              )
                            }
                          </div>
                        </React.Fragment>
                      ) : (
                        <h2 className={styles.notFound}>
                          Digi Score is not available
                        </h2>
                      )}
                    </div>
                    <div className={styles.experian_score}>
                      {experianScore ? (
                        <React.Fragment>
                          {' '}
                          <div className={styles.score_card}>
                            <div className={styles.icon}>
                              <Gauge size={100}/>
                            </div>
                            <div className={styles.desc}>
                              <h1>{experianScore.score}</h1>
                              <h3>i-SCORE</h3>
                              {/* <span>
                                Previous Score: <strong>650</strong>
                              </span> */}
                            </div>
                          </div>
                          <div className={styles.addinfo_container}>
                            <div className={styles.statistics}>
                              <span>Last Updated</span>
                              <h2>{experianScore.updatedDate}</h2>
                              <span className='date_info'>{moment(experianScore.updatedDate).fromNow()}</span>
                            </div>
                            <div className={styles.statistics}>
                              <span>Update Medium</span>
                              <h2>{experianScore.updateMedium}</h2>
                            </div>
                            <div className={styles.statistics}>
                              <span>Created Date</span>
                              <h2>{experianScore.createdDate}</h2>
                              <span className='date_info'>{moment(experianScore.createdDate).fromNow()}</span>
                            </div>
                            {
                              experianScore.updatedBy && experianScore.updateMedium === 'manual' && (
                                <div className={styles.statistics}>
                                  <span>Updated By</span>
                                  <h2>{trimUsername(experianScore.updatedBy)}</h2>
                                </div>
                              )
                            }
                          </div>
                        </React.Fragment>
                      ) : (
                        <h2 className={styles.notFound}>
                          i-Score is not available
                        </h2>
                      )}
                    </div>
                  </div>
                  { isAdmin && renderTenureArpuCv()}
                </div>
                <div className={styles.history}>
                  <History data={history} />
                </div>
              </div>
              <EligibleCombos id={id} eligibleCombos={customerData.eligibleCombos} finalScoreColour={finalScoreColour}/>
              {showChangeScore ? <ChangeScore hideForm={hideForm} customerData={customerData.customer.data}/> : null}
            </div>
          </div>
        )}
    </div>
  )
}

export default CustomerDetails
