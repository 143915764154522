import { createSlice } from '@reduxjs/toolkit'

import {
  fetchAllCombo,
  saveCombo,
  removeCombo,
  getCombo,
  enableCombo,
  updateCombo
} from '../services/Combo.services'

type TInitialStateData = {
  loading: 'idle' | 'pending' | 'failed' | 'completed'
  error: null | string | undefined
  data: any
  totalData?: number
  navKeys?: any
  isNext?: boolean
  isPrev?: boolean
}

type TInitialState = {
  getall: TInitialStateData
  enable: TInitialStateData
  addnew: TInitialStateData
  getdata: TInitialStateData
  search: TInitialStateData
  delete: TInitialStateData
  update: TInitialStateData
  isEditMode: boolean
}
const initialStateData: TInitialStateData = {
  loading: 'idle',
  error: null,
  data: [],
  totalData: 0
}

const initialState: TInitialState = {
  getall: initialStateData,
  enable: initialStateData,
  addnew: initialStateData,
  getdata: initialStateData,
  search: initialStateData,
  delete: initialStateData,
  update: initialStateData,
  isEditMode: false
}

const combo = createSlice({
  name: 'combo',
  initialState,
  reducers: {
    reset: (state, { payload }) => {
      switch (payload) {
        case 'addnew':
          state.addnew = initialStateData

          break
        case 'getdata':
          state.getdata = initialStateData
          state.isEditMode = false

          break
        case 'enable':
          state.enable = initialStateData

          break
        case 'search':
          state.search = initialStateData

          break
        case 'update':
            state.update = initialStateData

            break
        case 'delete':
          state.delete = initialStateData
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCombo.pending, (state) => {
        state.getall.loading = 'pending'
        state.getall.error = null
        state.getall.data = []
      })
      .addCase(fetchAllCombo.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.getall.loading = 'completed'
          state.getall.data = payload.data
          state.getall.totalData = payload.total
          state.getall.error = null

          if (payload.key && payload.type === 'next') {
            state.getall.navKeys.push(payload.key)
            state.getall.isNext = true
            state.getall.isPrev = true
          } else if (payload.key && payload.type === 'prev') {
            state.getall.navKeys.pop()
            state.getall.isNext = true
            state.getall.isPrev = true
          } else if (!payload.key && payload.type === 'next') {
            state.getall.isNext = false
            state.getall.isPrev = true
          } else {
            state.getall.navKeys = []
            state.getall.isPrev = false

            if (payload.key === undefined) {
              state.getall.isNext = false
            } else {
              state.getall.isNext = true
              state.getall.navKeys.push(payload.key)
            }

            state.getall.totalData = payload.total
          }
        }
      })
      .addCase(fetchAllCombo.rejected, (state, { payload }) => {
        state.getall.loading = 'failed'
        state.getall.data = []
        state.getall.error = payload
      })

    builder
      .addCase(saveCombo.pending, (state) => {
        state.addnew.loading = 'pending'
        state.addnew.error = null
        state.addnew.data = ''
      })
      .addCase(saveCombo.fulfilled, (state) => {
        state.addnew.loading = 'completed'
        state.addnew.error = null
        state.addnew.data = ''
      })
      .addCase(saveCombo.rejected, (state, { payload }) => {
        state.addnew.loading = 'failed'
        state.addnew.error = payload
        state.addnew.data = ''
      })

    builder
      .addCase(removeCombo.pending, (state) => {
        state.delete.loading = 'pending'
        state.delete.error = null
        state.delete.data = ''
      })
      .addCase(removeCombo.fulfilled, (state) => {
        state.delete.loading = 'completed'
        state.delete.error = null
        state.delete.data = ''
      })
      .addCase(removeCombo.rejected, (state, { payload }) => {
        state.delete.loading = 'failed'
        state.delete.error = payload
        state.delete.data = ''
      })

    builder
      .addCase(getCombo.pending, (state) => {
        state.getdata.loading = 'pending'
        state.getdata.error = null
        state.getdata.data = []
      })
      .addCase(getCombo.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.getdata.loading = 'completed'
          state.getdata.data = payload.data
          state.getdata.error = null

          if (payload.editMode === true) {
            state.isEditMode = true
          }
        }
      })
      .addCase(getCombo.rejected, (state, { payload }) => {
        state.getdata.loading = 'failed'
        state.getdata.data = []
        state.getdata.error = payload
      })

    builder
      .addCase(enableCombo.pending, (state) => {
        state.enable.loading = 'pending'
        state.enable.error = null
        state.enable.data = []
      })
      .addCase(enableCombo.fulfilled, (state, { payload }) => {
        if (payload.status) {
          state.enable.loading = 'completed'
          state.enable.error = null
        }
      })
      .addCase(enableCombo.rejected, (state, { payload }) => {
        state.enable.loading = 'failed'
        state.enable.error = payload
      })

    builder
      .addCase(updateCombo.pending, (state) => {
        state.update.loading = 'pending'
        state.update.error = null
        state.update.data = []
      })
      .addCase(updateCombo.fulfilled, (state, { payload }) => {
        if (payload.status) {
          state.update.loading = 'completed'
          state.update.error = null
        }
      })
      .addCase(updateCombo.rejected, (state, { payload }) => {
        state.update.loading = 'failed'
        state.update.error = payload
      })
  }
})

export const { reset } = combo.actions

export default combo.reducer
