import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'
import DataTable from '../../DataTable/DataTable'
import { successAlert } from '../../../utils'
import {
  removeMapping,
  fetchAllMapping,
  getMapping
} from '../../../store/services/Mapping.services'
import { reset } from '../../../store/slices/Mapping.slice'
import Spinner from '../../Spinner/Spinner'

function MappingTable() {
  const dispatch = useAppDispatch()

  const [limit, setLimit] = useState<number>(0)

  const mapping = useAppSelector((state) => state.mapping)

  const [columns] = useState([
    // { text: '#', key: 'SK' },
    { text: 'Name', key: 'name' },
    // { text: 'Label', key: 'label' },
    { text: 'Description', key: 'description' },
    { text: 'Digi Score', key: 'digiScore' },
    // { text: 'Range Type', key: 'rangeType' },
    // { text: 'Updated By', key: 'updatedBy' },
    { text: '', key: 'edit|remove' }
  ])

  useEffect(() => {

    if (mapping.delete.loading === 'completed') {
      successAlert('The mapping data has been deleted', () => {
        dispatch(reset('delete'))
      })
      dispatch(fetchAllMapping({}))
    }

    if (mapping.addnew.loading === 'completed') {
      successAlert('The mapping data has been saved', () => {
        dispatch(reset('addnew'))
      })
      dispatch(fetchAllMapping({}))
    }

    if (mapping.update.loading === 'pending' && mapping.getall.loading === 'completed') {
      successAlert('The mapping data has been updated', () => {
        dispatch(reset('update'))
      })
      dispatch(fetchAllMapping({}))
    }
  }, [mapping.delete.loading, mapping.addnew.loading, mapping.update.loading])

  const removeMappingItem = (label: string) => {
    if (label.length > 0) {
      dispatch(removeMapping({ label: label }))
    }
  }

  const viewMappingItem = (label: string) => {
    if (label.length > 0) {
      dispatch(getMapping({ label: label, editMode: true }))
    }
  }

  const changeLimit = (limit: number) => {
    setLimit(limit)

    if (limit <= 0) {
      dispatch(fetchAllMapping({}))
    } else {
      dispatch(fetchAllMapping({ limit: limit }))
    }
  }

  const nextPagination = () => {
    if (mapping.getall.navKeys.length > 0 && mapping.getall.isNext === true) {
      const key = mapping.getall.navKeys[mapping.getall.navKeys.length - 1]

      dispatch(
        fetchAllMapping({
          limit: limit,
          lastEvaluatedSK: key,
          type: 'next'
        })
      )
    }
  }

  const prevPagination = () => {
    if (mapping.getall.navKeys.length > 0 && mapping.getall.isPrev === true) {
      const key = mapping.getall.navKeys[mapping.getall.navKeys.length - 2]

      if (key === undefined) {
        dispatch(
          fetchAllMapping({
            limit: limit
          })
        )
      } else {
        dispatch(
          fetchAllMapping({
            limit: limit,
            lastEvaluatedSK: key,
            type: 'prev'
          })
        )
      }
    }
  }

  return (
    <React.Fragment>
      {mapping.delete.loading === 'pending' ||
      mapping.getall.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <DataTable
        columns={columns}
        data={mapping.getall.data}
        next={nextPagination}
        prev={prevPagination}
        prevDisabled={!mapping.getall.isPrev}
        nextDisabled={!mapping.getall.isNext}
        getLimitData={changeLimit}
        index={
          mapping.getall.isNext
            ? mapping.getall.navKeys && mapping.getall.navKeys.length - 1
            : mapping.getall.navKeys && mapping.getall.navKeys.length
        }
        totalData={mapping.getall.totalData}
        handleViewItem={viewMappingItem}
        handleRemoveItem={removeMappingItem}
        data_key="label"
      />
    </React.Fragment>
  )
}

export default MappingTable
