/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from 'react'
import { X } from 'tabler-icons-react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'

import { saveCustomer } from '../../../store/services/Customers.services'
import { fetchAllScore } from '../../../store/services/Scores.services'
import { scoreTypes } from '../../../config/constants'
import { isNumberKey } from '../../../utils/index'

import styles from '../../styles/FormComponent.module.css'

const initialInputValues = {
  msisdn: '',
  customerId: '',
  scoreType: '',
  score: '',
  tenure: 0,
  ARPU: 0,
  CV: 0
}

type TProps = {
  hideForm: () => void
}

function CustomerForm(props: TProps) {
  const [inputs, setInputs] = useState(initialInputValues)

  const dispatch = useAppDispatch()

  const [inputErrors, setInputErrors] = useState(initialInputValues)

  const scores = useAppSelector((state) => state.scores)

  useEffect(() => {
    dispatch(fetchAllScore({}))
  }, [])

  useEffect(() => {
    setInputs({ ...inputs, score: '' })
  }, [inputs.scoreType])

  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const handleSave = (type: string) => {
    let isValid = true
    let errors = {}

    errors = { ...initialInputValues }

    if (
      inputs.msisdn.length < 1 &&
      (inputs.scoreType === 'CELCOM' || inputs.scoreType === 'DIGI')
    ) {
      errors = { ...errors, msisdn: 'MSISDN is required' }
      isValid = false
    }

    if (inputs.customerId.length < 1) {
      errors = { ...errors, customerId: 'Customer ID is required' }
      isValid = false
    }

    if (inputs.scoreType.length < 1) {
      errors = { ...errors, scoreType: 'Score Type is required' }
      isValid = false
    }

    if (inputs.score.length < 1) {
      errors = { ...errors, score: 'Score is required' }
      isValid = false
    }

    setInputErrors({ ...inputErrors, ...errors })

    if (isValid === true) {
      let postParams = {}

      postParams = {
        scoreType: inputs.scoreType,
        score: inputs.score
      }

      // only include tenure, ARPU and CV if scoreType is DIGI

      if (inputs.scoreType === 'DIGI' || inputs.scoreType === 'CELCOM') {
        postParams = {
          ...postParams,
          tenure: inputs.tenure.toString(),
          ARPU: inputs.ARPU.toString(),
          CV: inputs.CV.toString()
        }
      }

      // temporarily patch i-SCORE to EXPERIAN during submission

      if (inputs.scoreType === 'i-SCORE') {
        postParams = { ...postParams, scoreType: 'EXPERIAN' }
      }

      if (inputs.msisdn.length > 0) {
        postParams = {
          ...postParams,
          msisdn: inputs.msisdn,
          customerId: inputs.customerId
        }
      } else {
        postParams = { ...postParams, customerId: inputs.customerId }
      }

      dispatch(saveCustomer(postParams))
    }
  }

  return (
    <React.Fragment>
      <div className={styles.form_wrapper}>
        <div className={styles.form_box}>
          <div className={styles.form_header}>
            <h2 className={styles.form_title}>Add New Customer</h2>
            <X size={22} onClick={() => props.hideForm()} />
          </div>
          <div className={styles.form}>
            <div className={styles.input_row}>
              <div className={styles.form_input}>
                {/* SELECT SCORE TYPE -- BY DEFAULT I-SCORE */}
                <label htmlFor="scoreType">Score Type</label>
                <select
                  name="scoreType"
                  onChange={(e) => handleInputChange(e)}
                  value={inputs.scoreType}
                >
                  <option value=""> - select -</option>
                  {scoreTypes.map((item) => {
                    return (
                      <option key={item.key} value={item.key}>
                        {item.value}
                      </option>
                    )
                  })}
                </select>
                {inputErrors.scoreType ? (
                  <span className={styles.input_error}>
                    {inputErrors.scoreType}
                  </span>
                ) : null}
              </div>
            </div>

            {inputs.scoreType === 'i-SCORE' && (
              <div className={styles.input_row}>
                <div className={styles.form_input}>
                  <label htmlFor="customerId">Customer ID</label>
                  <input
                    type="text"
                    autoComplete="off"
                    name="customerId"
                    id="customerId"
                    value={inputs.customerId}
                    onChange={(e) => handleInputChange(e)}
                  />
                  {inputErrors.customerId ? (
                    <span className={styles.input_error}>
                      {inputErrors.customerId}
                    </span>
                  ) : null}
                </div>
                <div className={styles.form_input}>
                  <label htmlFor="score">Score</label>
                  <input
                    type="text"
                    autoComplete="off"
                    name="score"
                    id="score"
                    onKeyPress={(e) => isNumberKey(e)}
                    value={inputs.score}
                    onChange={(e) => handleInputChange(e)}
                  />
                  {inputErrors.score ? (
                    <span className={styles.input_error}>
                      {inputErrors.score}
                    </span>
                  ) : null}
                </div>
              </div>
            )}

            {(inputs.scoreType === 'DIGI' || inputs.scoreType === 'CELCOM') && (
              <React.Fragment>
                <div className={styles.input_row}>
                  <div className={styles.form_input}>
                    <label htmlFor="msisdn">MSISDN</label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="msisdn"
                      id="msisdn"
                      value={inputs.msisdn}
                      onChange={(e) => handleInputChange(e)}
                    />
                    {inputErrors.msisdn ? (
                      <span className={styles.input_error}>
                        {inputErrors.msisdn}
                      </span>
                    ) : null}
                  </div>

                  <div className={styles.form_input}>
                    <label htmlFor="customerId">Customer Id</label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="customerId"
                      id="customerId"
                      value={inputs.customerId}
                      onChange={(e) => handleInputChange(e)}
                    />
                    {inputErrors.customerId ? (
                      <span className={styles.input_error}>
                        {inputErrors.customerId}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className={styles.input_row}>
                  <div className={styles.form_input}>
                    <label htmlFor="score">Score</label>
                    <select
                      name="score"
                      id="score"
                      value={inputs.score}
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option value="">- select - </option>
                      {scores.getall.data.map((item: any) => {
                        return (
                          <option key={item.SK} value={item.label}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                    {inputErrors.score ? (
                      <span className={styles.input_error}>
                        {inputErrors.score}
                      </span>
                    ) : null}
                  </div>
                  <div className={styles.form_input}>
                    <label htmlFor="tenure">Tenure (days)</label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="tenure"
                      id="tenure"
                      value={inputs.tenure}
                      onChange={(e) => handleInputChange(e)}
                    />
                    {inputErrors.tenure ? (
                      <span className={styles.input_error}>
                        {inputErrors.tenure}
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className={styles.input_row}>
                  <div className={styles.form_input}>
                    <label htmlFor="ARPU">ARPU (RM)</label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="ARPU"
                      id="ARPU"
                      value={inputs.ARPU}
                      onChange={(e) => handleInputChange(e)}
                    />
                    {inputErrors.ARPU ? (
                      <span className={styles.input_error}>
                        {inputErrors.ARPU}
                      </span>
                    ) : null}
                  </div>
                  <div className={styles.form_input}>
                    <label htmlFor="CV">Commitment Value (RM)</label>
                    <input
                      type="number"
                      autoComplete="off"
                      name="CV"
                      id="CV"
                      value={inputs.CV}
                      onChange={(e) => handleInputChange(e)}
                    />
                    {inputErrors.CV ? (
                      <span className={styles.input_error}>
                        {inputErrors.CV}
                      </span>
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            )}

            {inputs.scoreType === 'CD' && (
              <React.Fragment>
                <div className={styles.input_row}>
                  <div className={styles.form_input}>
                    <label htmlFor="customerId">Customer Id</label>
                    <input
                      type="text"
                      autoComplete="off"
                      name="customerId"
                      id="customerId"
                      value={inputs.customerId}
                      onChange={(e) => handleInputChange(e)}
                    />
                    {inputErrors.msisdn ? (
                      <span className={styles.input_error}>
                        {inputErrors.msisdn}
                      </span>
                    ) : null}
                  </div>

                  <div className={styles.form_input}>
                    <label htmlFor="score">Score</label>
                    <select
                      name="score"
                      id="score"
                      value={inputs.score}
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option value="">- select - </option>
                      {scores.getall.data.map((item: any) => {
                        return (
                          <option key={item.SK} value={item.label}>
                            {item.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className={styles.form_button}>
            <span className={styles.error_message}></span>
            <button type="button" onClick={() => handleSave('save')}>
              Save
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CustomerForm
