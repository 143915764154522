import React, { useContext, useEffect, useRef, useState } from 'react'
import CSVFileValidator from 'csv-file-validator'
import _ from 'lodash'
import { FileUpload, FileSpreadsheet, X } from 'tabler-icons-react'

import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks'

import { scoreTypes, digiScorePrefix } from '../../config/constants'
import ExperianCSVConfig from '../../config/experian-csv.config'
import DigiCSVConfig from '../../config/digi-csv.config'
import CdCSVConfig from '../../config/cd-csv.config'
import {
  groups as cognitoGroups,
  scoreTypes as scoreTypeValues
} from '../../config/values'

import { AuthContext } from '../../App'

import Header from '../../components/Header/Header'
import FileUploadRules from '../../components/UploadScore/FileUploadRules/FileUploadRules'
import Progress from '../../components/UploadScore/Progress/Progress'

import { uploadScore } from '../../store/services/UploadScore.services'
import { fetchAllScore } from '../../store/services/Scores.services'

import {
  chunk,
  failiureAlert,
  successAlert,
  isNumeric,
  prefixText
} from '../../utils'

import styles from './UploadScore.module.css'

type ProgressState = {
  icon: string
  title: string
  content: string
  status: string
  process: number
  error: string | null
  data?: { id: string; score: number }[] | null
  csvHeader?: string[]
}

type progressDataType = {
  fileVerification: ProgressState
  dataVerification: ProgressState
  uploadProcess: ProgressState
}

const progressInitialData = {
  fileVerification: {
    icon: 'file-check',
    title: 'Verify file',
    content: 'waiting csv file...',
    status: 'idle',
    process: 0,
    error: null
  },
  dataVerification: {
    icon: 'data-check',
    title: 'Validate csv records',
    content: 'waiting for csv data...',
    status: 'idle',
    process: 0,
    error: null,
    data: null,
    header: []
  },
  uploadProcess: {
    icon: 'cloud-upload',
    title: 'Upload to cloud',
    content: 'waiting for process....',
    status: 'idle',
    process: 0,
    error: null
  }
}

function UploadScore() {
  const dispatch = useAppDispatch(),
    authentication = useContext(AuthContext)

  const groups =
    authentication.user?.signInUserSession.accessToken.payload[
      'cognito:groups'
    ] || []

  const isAdmin = groups.includes(cognitoGroups.icsAdmin),
    isDigiFileUploader = groups.includes(cognitoGroups.icsDigiFileUploader),
    isExperianFileUploader = groups.includes(
      cognitoGroups.icsExperianFileUploader
    )

  const newScoreTypes = _.map(scoreTypes, (scoreType) => {
    if (
      (scoreType.key === scoreTypeValues.digi ||
        scoreType.key === scoreTypeValues.celcom ||
        scoreType.key === scoreTypeValues.cd) &&
      (isAdmin || isDigiFileUploader)
    ) {
      return scoreType
    }

    if (
      scoreType.key === scoreTypeValues.experian &&
      (isAdmin || isExperianFileUploader)
    ) {
      return scoreType
    }
  })

  const upload_score = useAppSelector((state) => state.uploadScore)

  const scores = useAppSelector((state) => {
    return state.scores
  })

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const [uploadFile, setUploadFile] = useState<File | null>(null)

  const [uploadStarted, setUploadStarted] = useState<boolean>(false)

  const [scoreType, setScoreType] = useState<string>('')

  const [DIGIscore, setDIGIscore] = useState<string>('')

  const [progressData, setProgressData] =
    useState<progressDataType>(progressInitialData)

  const fileUploadIconClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (e: any) => {
    if (e.target.files !== null && e.target.files.length > 0) {
      setUploadFile(e.target.files[0])
      e.target.value = ''
    }
  }

  const clearFileInput = () => {
    setUploadFile(null)
    setUploadStarted(false)
    setProgressData(progressInitialData)
    setScoreType('')
    setDIGIscore('')
  }

  const getCSVConfig = (scoreType: string) => {
    if (scoreType === 'DIGI' || scoreType === 'CELCOM') {
      return DigiCSVConfig
    } else if (scoreType === 'CD') {
      return CdCSVConfig
    } else {
      return ExperianCSVConfig
    }
  }

  useEffect(() => {
    dispatch(fetchAllScore({}))
  }, [])

  // File format and extensions checking
  useEffect(() => {
    if (uploadStarted === true) {
      if (scoreType.length <= 0) {
        setUploadStarted(false)
        failiureAlert('Score Type is required', () => {})
      } else {
        let fileChecking = true

        setProgressData({
          ...progressData,
          fileVerification: {
            ...progressData.fileVerification,
            status: 'pending',
            process: 10,
            content: 'verifying file...'
          }
        })

        if (uploadFile == null) {
          fileChecking = false

          setProgressData({
            ...progressData,
            fileVerification: {
              ...progressData.fileVerification,
              status: 'failed',
              process: 20,
              content: 'Could not find upload file.',
              error: 'Could not find upload file.'
            }
          })
        }

        if (uploadFile?.type !== 'text/csv' && fileChecking === true) {
          fileChecking = false
          setProgressData({
            ...progressData,
            fileVerification: {
              ...progressData.fileVerification,
              status: 'failed',
              process: 40,
              content: 'Uploaded file is not valid.',
              error: ''
            }
          })
        }

        if (fileChecking === true) {
          setProgressData({
            ...progressData,
            fileVerification: {
              ...progressData.fileVerification,
              status: 'completed',
              process: 100,
              content: 'File has been verified successfully.'
            }
          })
        }

        if (progressData.fileVerification.status === 'completed') {
        }
      }
    }
  }, [uploadStarted])

  // verify score data

  useEffect(() => {
    if (progressData.fileVerification.status === 'completed') {
      setProgressData({
        ...progressData,
        dataVerification: {
          ...progressData.dataVerification,
          status: 'pending',
          process: 10,
          content: 'verifying the score data...'
        }
      })

      CSVFileValidator(uploadFile ?? '', getCSVConfig(scoreType))
        .then((result) => {
          if (result.inValidMessages.length > 0) {
            setProgressData({
              ...progressData,
              dataVerification: {
                ...progressData.dataVerification,
                status: 'failed',
                process: 70,
                content: result.inValidMessages[0]
              }
            })
          } else {
            const csv_data = result.data

            if (csv_data && scoreType === 'DIGI') {
              const scoreData = scores.getall.data.find(
                (item: any) =>
                  item.label === prefixText(digiScorePrefix, csv_data[0].SCORE)
              )

              if (!scoreData) {
                failiureAlert('Score value is not valid', () => {})
                clearFileInput()
              }

              const DGscore = DIGIscore.substring(1, DIGIscore.length)

              const scoreIsUnmatched = _.find(csv_data, function (obj) {
                if (obj.SCORE !== DGscore) {
                  return true
                }
              })

              if (scoreIsUnmatched) {
                failiureAlert(
                  'Score value is not match as selected DIGI score',
                  () => {}
                )
                clearFileInput()
              }
            } else {
              if (!isNumeric(csv_data[0].SCORE)) {
                failiureAlert('Score value is not valid', () => {})
                clearFileInput()
              }
            }

            // csv_data.shift()

            if (scoreType === 'DIGI' || scoreType === 'CELCOM') {
              setProgressData({
                ...progressData,
                dataVerification: {
                  ...progressData.dataVerification,
                  status: 'completed',
                  process: 100,
                  csvHeader: [
                    'SUBS_MSISDN',
                    'LN_SUBS_ID',
                    'SCORE',
                    'TENURE',
                    'ARPU',
                    'CF'
                  ],
                  data: csv_data,
                  content: result.data.length + ' - rows have been verified'
                }
              })
            } else if (scoreType === 'CD') {
              setProgressData({
                ...progressData,
                dataVerification: {
                  ...progressData.dataVerification,
                  status: 'completed',
                  process: 100,
                  csvHeader: [
                    'SUBS_MSISDN',
                    'LN_SUBS_ID',
                    'SCORE',
                    'TENURE',
                    'ARPU',
                    'CF',
                    'TELCO',
                    'CUSTOMER_ID_NUMBER'
                  ],
                  data: csv_data,
                  content: result.data.length + ' - rows have been verified'
                }
              })
            } else {
              setProgressData({
                ...progressData,
                dataVerification: {
                  ...progressData.dataVerification,
                  status: 'completed',
                  process: 100,
                  csvHeader: ['CUSTOMER_ID', 'SUBS_MSISDN', 'SCORE'],
                  data: csv_data,
                  content: result.data.length + ' - rows have been verified'
                }
              })
            }
          }
        })
        .catch((err) => {
          setProgressData({
            ...progressData,
            dataVerification: {
              ...progressData.dataVerification,
              status: 'failed',
              process: 60,
              content: err.response.message
            }
          })
        })
    }
  }, [progressData.fileVerification.status])

  // csv file upload to s3 drive
  useEffect(() => {
    if (progressData.dataVerification.status === 'completed') {
      setProgressData({
        ...progressData,
        uploadProcess: {
          ...progressData.uploadProcess,
          status: 'pending',
          process: 10,
          content: 'uploading the csv file...'
        }
      })

      if (
        progressData.dataVerification.data &&
        progressData.dataVerification.data?.length > 0
      ) {
        const csv_upload_limit: number = Number(
          process.env.REACT_APP_CSV_UPLOAD_LIMIT
        )

        setProgressData({
          ...progressData,
          uploadProcess: {
            ...progressData.uploadProcess,
            process: 30
          }
        })

        const chunkData = chunk(
          progressData.dataVerification.data,
          csv_upload_limit
        )

        setProgressData({
          ...progressData,
          uploadProcess: {
            ...progressData.uploadProcess,
            process: 50
          }
        })

        if (chunkData.length > 0) {
          const progressLimit = 40 / chunkData.length

          let progressInit = 50

          chunkData.forEach((chunkAry: any) => {
            chunkAry.unshift(progressData.dataVerification.csvHeader)
            progressInit = progressInit + progressLimit

            const newScoreType =
              scoreType === 'i-SCORE' ? 'EXPERIAN' : scoreType

            dispatch(
              uploadScore({
                rows: chunkAry.length,
                csvdata: chunkAry,
                scoreType: newScoreType
              })
            )

            setProgressData({
              ...progressData,
              uploadProcess: {
                ...progressData.uploadProcess,
                process: progressInit
              }
            })
          })

          setProgressData({
            ...progressData,
            uploadProcess: {
              ...progressData.uploadProcess,
              process: progressInit,
              status: 'upload_requested'
            }
          })
        }
      }
    }
  }, [progressData.dataVerification.status])

  useEffect(() => {
    if (
      upload_score.loading === 'completed' &&
      progressData.uploadProcess.status === 'upload_requested'
    ) {
      setProgressData({
        ...progressData,
        uploadProcess: {
          ...progressData.uploadProcess,
          status: 'completed',
          process: 100,
          content: 'All files have been uploaded '
        }
      })

      successAlert('File has been successfully uploaded', () => {
        clearFileInput()
      })
    }
  }, [upload_score])

  let fileInputContent = (
    <React.Fragment>
      <FileUpload
        onClick={() => fileUploadIconClick()}
        size={112}
        color="#f5f5f5"
      />
      <span>Click icon to upload .csv file</span>
    </React.Fragment>
  )

  if (uploadFile !== null) {
    fileInputContent = (
      <React.Fragment>
        <div className={styles.uploadedInputFile}>
          <X
            className={styles.clearUploadFile}
            size={20}
            color="red"
            onClick={() => clearFileInput()}
          />
          <FileSpreadsheet
            size={112}
            color="#f5f5f5"
            className={styles.uploadedIcon}
          />
        </div>
        <span className={styles.uploadedName}>{uploadFile.name}</span>
      </React.Fragment>
    )
  }

  return (
    <div className="wrapper">
      <Header />
      <div className={styles.page_container}>
        <div className={styles.container}>
          <div className={styles.upload_box_container}>
            <div className={styles.upload_book_left}>
              <h2>Upload Score</h2>
              <div className={styles.upload_box}>
                {fileInputContent}
                <div className={styles.upload_actions}>
                  <div className={styles.upload_select}>
                    <label htmlFor="scoreType">Score Type</label>
                    <select
                      name="scoreType"
                      value={scoreType}
                      onChange={(e) => setScoreType(e.target.value)}
                    >
                      <option value="">- select - </option>
                      {newScoreTypes.map((item) => {
                        if (item) {
                          return (
                            <option key={item.key} value={item.key}>
                              {item.value}
                            </option>
                          )
                        }

                        return null
                      })}
                    </select>
                    {(scoreType === 'DIGI' ||
                      scoreType === 'CELCOM' ||
                      scoreType === 'CD') && (
                      <div className={styles.upload_select}>
                        <label htmlFor="scoreType">CelcomDigi Score</label>
                        <select
                          name="scoreType"
                          value={DIGIscore}
                          onChange={(e) => setDIGIscore(e.target.value)}
                        >
                          <option value="">- select -</option>
                          {scores.getall.data.map((item: any) => {
                            return (
                              <option key={item.SK} value={item.label}>
                                {`${item.name} (${item.label})`}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
                {scoreType ? (
                  <div>
                    <span>
                      Make sure the file to be uploaded is following the
                      following rules:
                    </span>
                    <FileUploadRules scoreType={scoreType} />
                  </div>
                ) : null}
                <button
                  disabled={uploadFile ? false : true}
                  className={styles.upload_button}
                  type="button"
                  onClick={() => setUploadStarted(true)}
                >
                  {uploadStarted ? <div className="dot-pulse"></div> : 'Upload'}
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  accept=".csv"
                  id="uploadInput"
                  onChange={(e) => handleFileChange(e)}
                  className={styles.upload_input}
                />
              </div>
            </div>
            <div className={styles.upload_book_right}>
              <h2>Progress</h2>
              <div className={styles.progress_box}>
                <Progress {...progressData.fileVerification} />
                <Progress {...progressData.dataVerification} />
                <Progress {...progressData.uploadProcess} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadScore
