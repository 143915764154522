import React from 'react'
import styles from './Header.module.css'
import HeaderTop from './HeaderTop/HeaderTop'
import Navbar from './Navbar/Navbar'

function Header() {
  return (
    <div className={styles.header_wrapper}>
      <HeaderTop />
      <Navbar />
    </div>
  )
}

export default Header
