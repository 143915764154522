export type csvConfigType = {
  headers: {
    name: string
    inputName: string
    optional: boolean
    unique?: boolean
    uniqueError?: (headerName: string) => string
    requiredError?: (
      headerName: string,
      rowNumber: number,
      columnNumber: number
    ) => string
    validate?: (value: string) => boolean
    validateError?: (
      headerName: string,
      rowNumber: number,
      columnNumber: number
    ) => string
  }[]
  isHeaderNameOptional: boolean
  parserConfig: any
}

// let scoreValue: string | null = null

// const isCheckScore = (value: string) => {
//   console.log('scoreValue')
//   console.log(scoreValue)

//   if (scoreValue === null) {
//     scoreValue = value
//   }

//   if (scoreValue !== value) {
//     return false
//   }

//   return true
// }

const CdCSVConfig: csvConfigType = {
  headers: [
    {
      name: 'SUBS_MSISDN',
      inputName: 'SUBS_MSISDN',
      optional: false,
      requiredError: function (
        headerName: string,
        rowNumber: number,
        columnNumber: number
      ) {
        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
      }
    },
    {
      name: 'LN_SUBS_ID',
      inputName: 'LN_SUBS_ID',
      optional: true
    },
    {
      name: 'SCORE',
      inputName: 'SCORE',
      optional: false,
      validateError: function (
        headerName: string,
        rowNumber: number,
        columnNumber: number
      ) {
        return `Invalid score value in the ${rowNumber} row / ${columnNumber} column`
      },
      requiredError: function (
        headerName: string,
        rowNumber: number,
        columnNumber: number
      ) {
        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
      }
    },
    {
      name: 'TENURE',
      inputName: 'TENURE',
      optional: true
    },
    {
      name: 'ARPU',
      inputName: 'ARPU',
      optional: true
    },
    {
      name: 'CF',
      inputName: 'CF',
      optional: true
    },
    {
      name: 'TELCO',
      inputName: 'TELCO',
      optional: false,
      requiredError: function (
        headerName: string,
        rowNumber: number,
        columnNumber: number
      ) {
        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
      }
    },
    {
      name: 'CUSTOMER_ID_NUMBER',
      inputName: 'CUSTOMER_ID_NUMBER',
      optional: false,
      requiredError: function (
        headerName: string,
        rowNumber: number,
        columnNumber: number
      ) {
        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
      }
    }
  ],
  isHeaderNameOptional: false,
  parserConfig: { skipEmptyLines: true }
}

export default CdCSVConfig
