import React, { useEffect, useState } from 'react'
import ScoreForm from '../../components/Scores/ScoreForm/ScoreForm'
import ScoreTable from '../../components/Scores/ScoreTable/ScoreTable'
import Header from '../../components/Header/Header'
import { useAppSelector } from '../../hooks/redux-hooks'
import Spinner from '../../components/Spinner/Spinner'
import Swal from 'sweetalert2'

import styles from '../styles/FormPage.module.css'
// import SearchData from '../../components/DataTable/SearchData/SearchData'

function Scores() {
  const scores = useAppSelector((state) => state.scores)

  const [showScore, SetShowScore] = useState(false)

  useEffect(() => {
    if (scores.addnew.loading === 'failed') {
      Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: scores.addnew.error ? scores.addnew.error : 'unknown'
      })
    }

    if (scores.addnew.loading === 'completed') {
      SetShowScore(false)
    }
  }, [scores])

  const hideForm = () => {
    SetShowScore(false)
  }

  return (
    <div className="wrapper">
      {scores.getall.loading === 'pending' ||
      scores.delete.loading === 'pending' ||
      scores.addnew.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <Header />
      <div className={styles.page_container}>
        <div className={styles.container}>
          <div className={styles.tableBody}>
            <div className={styles.tableTitle}>
              <div className={styles.title}>
                <h2 data-testid="scores-container-title">Scores</h2>
              </div>
              <div className={styles.right_buttons}>
                {/* <SearchData name="scores" /> */}
                <button onClick={() => SetShowScore(true)}>Add New</button>
              </div>
            </div>
            <ScoreTable />
          </div>
        </div>
      </div>
      {showScore ? <ScoreForm hideForm={hideForm} /> : null}
    </div>
  )
}

export default Scores
