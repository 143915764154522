import { groups } from './values'

type TKeyValuePair = {
  key: string;
  value: string;
  description?: string;
}

export const criteriaTypes: TKeyValuePair[] = [
  {
    key: 'external',
    value: 'External',
    description: '  External criteria will be validated againts the value passed by requester/channel.'
  },
  {
    key: 'internal',
    value: 'Internal',
    description: 'Internal criteria will be validated againts the value from the customer data coming from BI system.'
  }
]

export const digiScorePrefix: string = 'C'

export const criteriaFieldTypes: TKeyValuePair[] = [
  { key: 'text', value: 'Text' },
  { key: 'range', value: 'Range' }
]

export const eligibilityFieldTypes: TKeyValuePair[] = [
  { key: 'discountAmount', value: 'Discount by Amount' },
  { key: 'discountMonth', value: 'Discount by Month' },
  { key: 'discountPercentage', value: 'Discount by Percentage' },
  { key: 'freebieFlag', value: 'Freebie' }
]

export const rangeTypes: TKeyValuePair[] = [
  { key: 'moreThan', value: 'More Than' },
  { key: 'lessThan', value: 'Less Than' },
  { key: 'inBetween', value: 'In Between' }
]

export const scoreTypes: TKeyValuePair[] = [
  { key: 'i-SCORE', value: 'i-SCORE' },
  { key: 'DIGI', value: 'DIGI' },
  { key: 'CELCOM', value: 'CELCOM' },
  { key: 'CD', value: 'CD' }
]

export const sessionLength = 900000 // miliseconds

// user groups

const {
  icsAdmin,
  icsConfigManager,
  icsDigiFileUploader,
  icsExperianFileUploader,
  icsManualCreditScore
} = groups

export const userGroups: TKeyValuePair[] = [
  { key: icsAdmin, value: icsAdmin },
  { key: icsConfigManager, value: icsConfigManager },
  { key: icsDigiFileUploader, value: icsDigiFileUploader },
  { key: icsExperianFileUploader, value: icsExperianFileUploader },
  { key: icsManualCreditScore, value: icsManualCreditScore }
]
