import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'
import DataTable from '../../DataTable/DataTable'
import { successAlert } from '../../../utils'
import {
  removeEligibility,
  fetchAllEligibility
} from '../../../store/services/Eligibility.services'
import { reset } from '../../../store/slices/Eligibility.slice'
import Spinner from '../../Spinner/Spinner'

function EligibilityTable() {
  const dispatch = useAppDispatch()

  const [limit, setLimit] = useState<number>(0)

  const eligibility = useAppSelector((state) => state.eligibility)

  const [columns] = useState([
    // { text: '#', key: 'SK' },
    { text: 'Name', key: 'name' },
    // { text: 'Label', key: 'label' },
    { text: 'Description', key: 'description' },
    { text: 'Field Name', key: 'fieldName' },
    { text: 'Field Type', key: 'fieldType' },
    { text: 'Field Value', key: 'amount' },
    { text: 'Created Date', key: 'updatedDate' },
    { text: 'Created By', key: 'updatedBy' },
    { text: '', key: '|remove' }
  ])

  useEffect(() => {
    if (eligibility.delete.loading === 'completed') {
      successAlert('The eligibility data has been deleted', () => {
        dispatch(reset('delete'))
      })
      dispatch(fetchAllEligibility({}))
    }

    if (eligibility.addnew.loading === 'completed') {
      successAlert('The eligibility data has been saved', () => {
        dispatch(reset('addnew'))
      })
      dispatch(fetchAllEligibility({}))
    }
  }, [eligibility.delete.loading, eligibility.addnew.loading])

  const removeEligibilityItem = (label: string) => {
    if (label.length > 0) {
      dispatch(removeEligibility({ label: label }))
    }
  }

  const viewEligibilityItem = (label: string) => {}

  const changeLimit = (limit: number) => {
    setLimit(limit)

    if (limit <= 0) {
      dispatch(fetchAllEligibility({}))
    } else {
      dispatch(fetchAllEligibility({ limit: limit }))
    }
  }

  const nextPagination = () => {
    if (
      eligibility.getall.navKeys.length > 0 &&
      eligibility.getall.isNext === true
    ) {
      const key =
        eligibility.getall.navKeys[eligibility.getall.navKeys.length - 1]

      dispatch(
        fetchAllEligibility({
          limit: limit,
          lastEvaluatedSK: key,
          type: 'next'
        })
      )
    }
  }

  const prevPagination = () => {
    if (
      eligibility.getall.navKeys.length > 0 &&
      eligibility.getall.isPrev === true
    ) {
      const key =
        eligibility.getall.navKeys[eligibility.getall.navKeys.length - 2]

      if (key === undefined) {
        dispatch(
          fetchAllEligibility({
            limit: limit
          })
        )
      } else {
        dispatch(
          fetchAllEligibility({
            limit: limit,
            lastEvaluatedSK: key,
            type: 'prev'
          })
        )
      }
    }
  }

  return (
    <React.Fragment>
      {eligibility.delete.loading === 'pending' ||
      eligibility.getdata.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <DataTable
        columns={columns}
        data={eligibility.getall.data}
        next={nextPagination}
        prev={prevPagination}
        prevDisabled={!eligibility.getall.isPrev}
        nextDisabled={!eligibility.getall.isNext}
        getLimitData={changeLimit}
        index={
          eligibility.getall.isNext
            ? eligibility.getall.navKeys &&
              eligibility.getall.navKeys.length - 1
            : eligibility.getall.navKeys && eligibility.getall.navKeys.length
        }
        totalData={eligibility.getall.totalData}
        handleViewItem={viewEligibilityItem}
        handleRemoveItem={removeEligibilityItem}
        data_key="label"
      />
    </React.Fragment>
  )
}

export default EligibilityTable
