import { createSlice } from '@reduxjs/toolkit'
import { uploadScore } from '../services/UploadScore.services'

type initialStateType = {
  loading: string
  status: string | null
  error: string | null | undefined
}

const initialState = {
  loading: 'idle',
  status: null,
  error: null
} as initialStateType

const UploadScoreSlice = createSlice({
  name: 'uploadscore',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadScore.pending, (state) => {
        state.loading = 'pending'
        state.error = null
        state.status = null
      })
      .addCase(uploadScore.fulfilled, (state, { payload }) => {
        state.loading = 'completed'
        state.error = null
        state.status = payload?.status
      })
      .addCase(uploadScore.rejected, (state, { payload }) => {
        state.loading = 'failed'
        state.status = null
        state.error = payload
      })
  }
})

export default UploadScoreSlice.reducer
