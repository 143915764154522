import React, { useEffect, useState } from 'react'
import { X } from 'tabler-icons-react'
import moment from 'moment'

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'
import { saveCriteria } from '../../../store/services/Criteria.services'
import { isFloatKey, clean } from '../../../utils/index'
import {
  criteriaTypes,
  criteriaFieldTypes,
  rangeTypes
} from '../../../config/constants'
import styles from '../../styles/FormComponent.module.css'

const [ initialCriteriaType ] = criteriaTypes

const initialInputValues = {
  name: '',
  label: '',
  description: '',
  criteriaType: initialCriteriaType.key,
  fieldName: '',
  fieldType: 'text',
  rangeType: '',
  textValues: '',
  min_value: '',
  max_value: ''
}

type TProps = {
  hideForm: () => void
}

function CriteriaForm(props: TProps) {
  const [inputs, setInputs] = useState(initialInputValues)

  const [criteriaTypeDescription, setCriteriaTypeDescription] = useState(initialCriteriaType.description)

  const dispatch = useAppDispatch()

  const criteria = useAppSelector((state) => state.criteria)

  const [inputErrors, setInputErrors] = useState({
    ...initialInputValues,
    fieldType: ''
  })

  useEffect(() => {
    const
      matchingCriteriaType = criteriaTypes.find(criteriaType => criteriaType.key === inputs.criteriaType),
      matchingCriteriaTypeDescription = matchingCriteriaType?.description

      setCriteriaTypeDescription(matchingCriteriaTypeDescription)
  }, [inputs.criteriaType])

  useEffect(() => {
    setInputs({ ...inputs, textValues: '', max_value: '', min_value: '' })
  }, [inputs.fieldType])

  useEffect(() => {
    setInputs({ ...inputs, max_value: '', min_value: '' })
  }, [inputs.rangeType])

  useEffect(() => {
    if (criteria.isEditMode === true) {
      setInputs({
        ...criteria.getdata.data,
        min_value: criteria.getdata.data.rangeValue?.min,
        max_value: criteria.getdata.data.rangeValue?.max
      })
    }
  }, [criteria.isEditMode])

  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (e.target.name === 'name') {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
        label: clean(e.target.value, 'cr_')
      })
    } else if (e.target.name === 'textValues') {
      let value = (e.target.value || '')

      value = value.replace(/^\s+/g, '')
      value = value.replace(/\s*,\s*/g, ',')

      setInputs({ ...inputs, [e.target.name]: value })
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value })
    }
  }

  const handleSave = (type: string) => {
    let isValid = true
    let errors = {}

    errors = { ...initialInputValues }

    if (inputs.name.length < 1) {
      errors = { ...errors, name: 'Name is required' }
      isValid = false
    }

    if (inputs.label.length < 1) {
      errors = { ...errors, label: 'Label is required' }
      isValid = false
    }

    if (inputs.criteriaType.length < 1) {
      errors = { ...errors, name: 'Criteria Type is required' }
      isValid = false
    }

    if (inputs.fieldName.length < 1) {
      errors = { ...errors, fieldName: 'Field name is required' }
      isValid = false
    }

    if (inputs.fieldType === 'text') {
      if (inputs.textValues.length < 1) {
        errors = { ...errors, textValues: 'Text values is required' }
        isValid = false
      }
    }

    if (inputs.fieldType === 'range') {
      if (inputs.rangeType.length < 1) {
        errors = { ...errors, rangeType: 'Range type is required' }
        isValid = false
      }

      if (inputs.rangeType === 'lessThan') {
        if (
          isNaN(parseInt(inputs.max_value)) ||
          parseInt(inputs.max_value) <= 0
        ) {
          errors = { ...errors, max_value: 'Max value is required' }
          isValid = false
        }
      }

      if (inputs.rangeType === 'moreThan') {
        if (
          isNaN(parseInt(inputs.min_value)) ||
          parseInt(inputs.min_value) < 0
        ) {
          errors = { ...errors, min_value: 'Min value is required' }
          isValid = false
        }
      }

      if (inputs.rangeType === 'inBetween') {
        if (
          isNaN(parseInt(inputs.min_value)) ||
          parseInt(inputs.min_value) < 0
        ) {
          errors = { ...errors, min_value: 'Min value is required' }
          isValid = false
        }

        if (
          isNaN(parseInt(inputs.max_value)) ||
          parseInt(inputs.max_value) <= 0
        ) {
          errors = { ...errors, max_value: 'Max value is required' }
          isValid = false
        }
      }
    }

    setInputErrors({ ...inputErrors, ...errors })

    if (isValid === true) {
      let postParams = {}

      postParams = {
        name: inputs.name,
        label: inputs.label,
        description: inputs.description,
        criteriaType: inputs.criteriaType,
        fieldName: inputs.fieldName,
        fieldType: inputs.fieldType
      }

      if (inputs.fieldType === 'text') {
        const { textValues } = inputs

        const textValueArray = textValues.trim().split(',')

        postParams = { ...postParams, textValues: textValueArray }
      }

      if (inputs.fieldType === 'range') {
        postParams = { ...postParams, rangeType: inputs.rangeType }

        if (inputs.rangeType === 'inBetween') {
          postParams = {
            ...postParams,
            rangeValue: {
              min: parseInt(inputs.min_value.toString()),
              max: parseInt(inputs.max_value.toString())
            }
          }
        }

        if (inputs.rangeType === 'lessThan') {
          postParams = {
            ...postParams,
            rangeValue: { max: parseInt(inputs.max_value.toString()) }
          }
        }

        if (inputs.rangeType === 'moreThan') {
          postParams = {
            ...postParams,
            rangeValue: { min: parseInt(inputs.min_value.toString()) }
          }
        }
      }

      dispatch(saveCriteria(postParams))
    }
  }

  return (
    <div className={styles.form_wrapper}>
      <div className={styles.form_box}>
        <div className={styles.form_header}>
          <h2 className={styles.form_title}>
            {criteria.isEditMode === true
              ? 'Criteria Details'
              : 'Add New Criteria'}
          </h2>
          <X size={22} onClick={() => props.hideForm()} />
        </div>
        <div className={styles.form}>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="name">Name</label>
              <input
                maxLength={30}
                type="text"
                autoComplete="off"
                name="name"
                id="name"
                value={inputs.name}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.name ? (
                <span className={styles.input_error}>{inputErrors.name}</span>
              ) : null}
            </div>
            <div className={styles.form_input}>
              <label htmlFor="name">Criteria ID</label>
              <input
                readOnly
                type="text"
                autoComplete="off"
                name="label"
                id="label"
                value={inputs.label}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.label ? (
                <span className={styles.input_error}>{inputErrors.label}</span>
              ) : null}
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="name">Description</label>
              <input
                type="text"
                autoComplete="off"
                name="description"
                id="description"
                value={inputs.description}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="criteriaType">Criteria Type</label>
              <select
                name="criteriaType"
                onChange={(e) => handleInputChange(e)}
                value={inputs.criteriaType}
              >
                {criteriaTypes.map((item) => {
                  return (
                    <option key={item.key} value={item.key}>
                      {item.value}
                    </option>
                  )
                })}
              </select>
              <div className={styles.input_description}>{criteriaTypeDescription}</div>
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="fieldName">Field Name</label>
              <input
                type="text"
                autoComplete="off"
                name="fieldName"
                value={inputs.fieldName}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.fieldName ? (
                <span className={styles.input_error}>
                  {inputErrors.fieldName}
                </span>
              ) : null}
            </div>
            <div className={styles.form_input}>
              <label htmlFor="name">Field Type</label>
              <select
                name="fieldType"
                onChange={(e) => handleInputChange(e)}
                value={inputs.fieldType}
              >
                {criteriaFieldTypes.map((item) => {
                  return (
                    <option key={item.key} value={item.key}>
                      {item.value}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          {inputs.fieldType === 'text' ? (
            <div className={styles.input_row}>
              <div className={styles.form_input}>
                <label htmlFor="name">Text Values</label>
                <input
                  type="text"
                  autoComplete="off"
                  name="textValues"
                  value={inputs.textValues}
                  onChange={(e) => handleInputChange(e)}
                />
                {inputErrors.textValues ? (
                  <span className={styles.input_error}>
                    {inputErrors.textValues}
                  </span>
                ) : null}
                <div className={styles.input_description}>For multiple text values eg. "iPhone" or "Android", input the values seperated by comma eg. "iPhone,Android".</div>
              </div>
            </div>
          ) : null}
          {inputs.fieldType === 'range' ? (
            <div className={styles.input_row}>
              <div className={styles.form_input}>
                <label htmlFor="name">Range Type</label>
                <select
                  name="rangeType"
                  onChange={(e) => handleInputChange(e)}
                  value={inputs.rangeType}
                >
                  <option value="">- select - </option>
                  {rangeTypes.map((item) => {
                    return (
                      <option key={item.key} value={item.key}>
                        {item.value}
                      </option>
                    )
                  })}
                </select>
                {inputErrors.rangeType ? (
                  <span className={styles.input_error}>
                    {inputErrors.rangeType}
                  </span>
                ) : null}
              </div>
              {['moreThan', 'inBetween'].indexOf(inputs.rangeType) > -1 ? (
                <div className={styles.form_input}>
                  <label htmlFor="name">Min Value</label>
                  <input
                    type="text"
                    autoComplete="off"
                    name="min_value"
                    value={criteria.getdata.data.rangeValue?.min}
                    onKeyPress={(e) => isFloatKey(e)}
                    onChange={(e) => handleInputChange(e)}
                  />
                  {inputErrors.min_value ? (
                    <span className={styles.input_error}>
                      {inputErrors.min_value}
                    </span>
                  ) : null}
                </div>
              ) : null}
              {['lessThan', 'inBetween'].indexOf(inputs.rangeType) > -1 ? (
                <div className={styles.form_input}>
                  <label htmlFor="name">Max Value</label>
                  <input
                    type="text"
                    autoComplete="off"
                    name="max_value"
                    onKeyPress={(e) => isFloatKey(e)}
                    value={criteria.getdata.data.rangeValue?.max}
                    onChange={(e) => handleInputChange(e)}
                  />
                  {inputErrors.max_value ? (
                    <span className={styles.input_error}>
                      {inputErrors.max_value}
                    </span>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}

          {criteria.isEditMode === true ? (
            <React.Fragment>
              <div className={styles.divider} />
              <div className={[styles.input_row, styles.readonly_group].join(' ')}>
                <div className={styles.form_input}>
                  <label>Created Date</label>
                  <input
                    type="text"
                    value={criteria.getdata.data.updatedDate}
                    readOnly={true}
                  />
                  <span className='input_date_info'>{moment(criteria.getdata.data.updatedDate).fromNow()}</span>
                </div>
                <div className={styles.form_input}>
                  <label>Created By</label>
                  <input
                    type="text"
                    value={criteria.getdata.data.updatedBy}
                    readOnly={true}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </div>
        {criteria.isEditMode === false ? (
          <div className={styles.form_button}>
            <span className={styles.error_message}></span>
            <button
              type="button"
              onClick={() => {
                handleSave('save')
              }}
            >
              Save
            </button>
          </div>
        ) : ( null )}
      </div>
    </div>
  )
}

export default CriteriaForm
