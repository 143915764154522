import React, { createContext, useEffect, useState } from 'react'
import { Amplify, Auth } from 'aws-amplify'
import _ from 'lodash'
import Swal from 'sweetalert2'

import awsConfig from './config/AwsConfig'
import { sessionLength, userGroups } from './config/constants'

import Routers from './Router'

import PageNotFound from './containers/PageNotFound/PageNotFound'

import Spinner from './components/Spinner/Spinner'

import './sass/aws.css'

Amplify.configure(awsConfig)

export const AuthContext = createContext<{ user: any }>({ user: null })

function App() {
  const [user, setUser] = useState(null)

  let tokenTimeoutTimer: any = null

  async function routeToLogin() {
    // temp change this in prod to fix AD login infinite loop,
    // issue on AD login,
    // reopen the comment lines once the issue is fixed

    // if (process.env.NODE_ENV === 'production') {
    //   const provider = process.env.REACT_APP_AUTH_PROVIDER || ''

    //   Auth.federatedSignIn({ customProvider: provider })
    // } else {
    Auth.federatedSignIn()
    // }
  }

  function signOut() {
    setUser(null)

    sessionStorage.removeItem('auth-token')
    sessionStorage.removeItem('refresh-token')

    Auth.signOut()

    routeToLogin()
  }

  useEffect(() => {
    getUser()
  }, [])

  async function getUser() {
    let userData

    try {
      userData = await Auth.currentAuthenticatedUser()
    } catch (error) {
      routeToLogin()
    }

    sessionStorage.setItem(
      'auth-token',
      userData.signInUserSession.idToken.jwtToken
    )

    sessionStorage.setItem(
      'refresh-token',
      userData.signInUserSession.refreshToken.token
    )

    sessionStorage.setItem(
      'groups',
      userData.signInUserSession.idToken.payload['cognito:groups']
    )

    setUser(userData)

    if (!tokenTimeoutTimer) {
      tokenTimeoutTimer = setTimeout(() => {
        Swal.fire({
          title: 'Hello!',
          text: 'Your session is about to expire.',
          icon: 'warning',
          confirmButtonText: 'Continue',
          showDenyButton: true,
          denyButtonText: 'Sign Out'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          } else if (result.isDenied) {
            signOut()
          }
        })
      }, sessionLength)
    }
  }

  function getGroups(groups: any) {
    const
      groupsArray = groups.split(','),
      userGroupsValues = _.map(userGroups, 'value')

    if (groupsArray && userGroupsValues.some(e => groupsArray.includes(e))) {
      return true
    }

    return false
  }

  return (
    <AuthContext.Provider value={{ user }}>
      {
        user ? (
          getGroups(sessionStorage.getItem('groups')) ? <Routers /> : <PageNotFound/>
        ) : <Spinner />
      }
    </AuthContext.Provider>
  )
}

export default App
