import React, { useEffect, useState } from 'react'
import CriteriaForm from '../../components/Criteria/CriteriaForm/CriteriaForm'
import CriteriaTable from '../../components/Criteria/CriteriaTable/CriteriaTable'
import Header from '../../components/Header/Header'
import { useAppSelector, useAppDispatch } from '../../hooks/redux-hooks'
import Spinner from '../../components/Spinner/Spinner'
import Swal from 'sweetalert2'
import { reset } from '../../store/slices/Criteria.slice'
import styles from '../styles/FormPage.module.css'
// import SearchData from '../../components/DataTable/SearchData/SearchData'

function Criteria() {
  const criteria = useAppSelector((state) => state.criteria)

  const dispatch = useAppDispatch()

  const [showCriteria, SetShowCriteria] = useState(false)

  useEffect(() => {
    if (criteria.addnew.loading === 'failed') {
      Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: criteria.addnew.error ? criteria.addnew.error : 'unknown'
      })
    }

    if (criteria.addnew.loading === 'completed') {
      SetShowCriteria(false)
    }
  }, [criteria])

  useEffect(() => {
    if (criteria.isEditMode === true) {
      SetShowCriteria(true)
    }
  }, [criteria.isEditMode])

  const hideForm = () => {
    if (criteria.isEditMode === true) {
      dispatch(reset('getdata'))
    }

    SetShowCriteria(false)
  }

  return (
    <div className="wrapper">
      {criteria.getall.loading === 'pending' ||
      criteria.delete.loading === 'pending' ||
      criteria.addnew.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <Header />
      <div className={styles.page_container}>
        <div className={styles.container}>
          <div className={styles.tableBody}>
            <div className={styles.tableTitle}>
              <div className={styles.title}>
                <h2 data-testid="criteria-container-title">Criteria</h2>
              </div>
              <div className={styles.right_buttons}>
                {/* -<SearchData name="criteria" /> */}
                <button onClick={() => SetShowCriteria(true)}>Add New</button>
              </div>
            </div>
            <CriteriaTable />
          </div>
        </div>
      </div>
      {showCriteria ? <CriteriaForm hideForm={hideForm} /> : null}
    </div>
  )
}

export default Criteria
