import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'
import DataTable from '../../DataTable/DataTable'
import { successAlert } from '../../../utils'
import {
  removeCombo,
  fetchAllCombo,
  getCombo
} from '../../../store/services/Combo.services'
import { reset } from '../../../store/slices/Combo.slice'
import Spinner from '../../Spinner/Spinner'

function ComboTable() {
  const dispatch = useAppDispatch()

  const [limit, setLimit] = useState<number>(0)

  const combo = useAppSelector((state) => state.combo)

  const [columns] = useState([
    // { text: '#', key: 'SK' },
    { text: 'Name', key: 'name' },
    // { text: 'Label', key: 'label' },
    { text: 'Enabled', key: '#enabled' },
    // { text: 'Created Date', key: 'createdDate' },
    // { text: 'Updated Date', key: 'updatedDate' },
    // { text: 'Updated By', key: 'updatedBy' },
    { text: '', key: 'edit|remove' }
  ])

  useEffect(() => {
    if (combo.delete.loading === 'completed') {
      successAlert('The combo data has been deleted', () => {
        dispatch(reset('delete'))
      })
      dispatch(fetchAllCombo({}))
    }

    if (combo.enable.loading === 'completed') {
      successAlert('The combo status has been updated', () => {
        dispatch(reset('enable'))
      })
      dispatch(fetchAllCombo({}))
    }

    if (combo.update.loading === 'completed') {
      successAlert('The combo details has been updated', () => {
        dispatch(reset('update'))
      })
      dispatch(fetchAllCombo({}))
    }
  }, [combo.delete.loading, combo.addnew.loading, combo.enable.loading, combo.update.loading])

  const removeComboItem = (label: string) => {
    if (label.length > 0) {
      dispatch(removeCombo({ label: label }))
    }
  }

  const viewComboItem = (label: string) => {
    if (label.length > 0) {
      dispatch(getCombo({ label: label, editMode: true }))
    }
  }

  const changeLimit = (limit: number) => {
    setLimit(limit)

    if (limit <= 0) {
      dispatch(fetchAllCombo({}))
    } else {
      dispatch(fetchAllCombo({ limit: limit }))
    }
  }

  const nextPagination = () => {
    if (combo.getall.navKeys.length > 0 && combo.getall.isNext === true) {
      const key = combo.getall.navKeys[combo.getall.navKeys.length - 1]

      dispatch(
        fetchAllCombo({
          limit: limit,
          lastEvaluatedSK: key,
          type: 'next'
        })
      )
    }
  }

  const prevPagination = () => {
    if (combo.getall.navKeys.length > 0 && combo.getall.isPrev === true) {
      const key = combo.getall.navKeys[combo.getall.navKeys.length - 2]

      if (key === undefined) {
        dispatch(
          fetchAllCombo({
            limit: limit
          })
        )
      } else {
        dispatch(
          fetchAllCombo({
            limit: limit,
            lastEvaluatedSK: key,
            type: 'prev'
          })
        )
      }
    }
  }

  return (
    <React.Fragment>
      {combo.delete.loading === 'pending' ||
      combo.enable.loading === 'pending' ||
      combo.getdata.loading === 'pending' ||
      combo.update.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <DataTable
        columns={columns}
        data={combo.getall.data}
        next={nextPagination}
        prev={prevPagination}
        prevDisabled={!combo.getall.isPrev}
        nextDisabled={!combo.getall.isNext}
        getLimitData={changeLimit}
        index={
          combo.getall.isNext
            ? combo.getall.navKeys && combo.getall.navKeys.length - 1
            : combo.getall.navKeys && combo.getall.navKeys.length
        }
        totalData={combo.getall.totalData}
        handleViewItem={viewComboItem}
        handleRemoveItem={removeComboItem}
        data_key="label"
      />
    </React.Fragment>
  )
}

export default ComboTable
