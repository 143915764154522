import React, { useEffect, useState } from 'react'
import {
  X,
  Edit,
  ChevronLeft,
  ChevronRight,
  Eye,
  Check
} from 'tabler-icons-react'
import moment from 'moment'
import Swal from 'sweetalert2'

import styles from './DataTable.module.css'

function DataTable(props: any) {
  const [dataKeys, setDataKeys] = useState([])

  const [limit, setLimit] = useState<number>(10)

  useEffect(() => {
    const keys = props.columns.reduce((acc: any, cur: any) => {
      acc.push(cur.key)

      return acc
    }, [])

    setDataKeys(keys)
  }, [props.columns])

  useEffect(() => {
    props.getLimitData(limit)
  }, [limit])

  const changeLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setLimit(parseInt(e.target.value))
    }
  }

  const handleClickRemove = (item_label: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `You won't be able to revert this data (${item_label})`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#206bc4',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        props.handleRemoveItem(item_label)
      }
    })
  }

  const handleClickView = (item_label: string) => {
    props.handleViewItem(item_label)
  }

  return (
    <div className={styles.table_container}>
      <table className={styles.table}>
        <thead>
          <tr>
            {props.columns &&
              props.columns.map((item: any, index: number) => {
                return (
                  <th
                    key={'header' + index}
                    className={[styles.tableHd, item.class_name].join(' ')}
                  >
                    {item.text}
                  </th>
                )
              })}
          </tr>
        </thead>
        <tbody>
          {props.data.length > 0 &&
            props.data.map((item: any, index: number) => {
              return (
                <tr key={'row' + index}>
                  {dataKeys &&
                    dataKeys.map((k: string, index: number) => {
                      const keys: any = k && k.split('|')

                      if (keys.length > 1) {
                        return (
                          <td
                            key={'key' + index}
                            className={styles.tableActionTd}
                          >
                            <div className={styles.tableAction}>
                              {keys.map((k2: string, index: number) => {
                                if (k2 === 'edit') {
                                  return (
                                    <button
                                      key={'btn_' + index}
                                      className={styles.btn}
                                      type="button"
                                      onClick={() =>
                                        handleClickView(item[props.data_key])
                                      }
                                    >
                                      <Edit size={16} />
                                    </button>
                                  )
                                } else if (k2 === 'remove') {
                                  return (
                                    <button
                                      onClick={() =>
                                        handleClickRemove(item[props.data_key])
                                      }
                                      key={'btn_' + index}
                                      type="button"
                                      className={styles.btn}
                                    >
                                      <X size={16} />
                                    </button>
                                  )
                                } else if (k2 === 'view') {
                                  return (
                                    <button
                                      onClick={() =>
                                        handleClickView(item[props.data_key])
                                      }
                                      key={'btn_' + index}
                                      type="button"
                                      className={styles.btn}
                                    >
                                      <Eye size={16} />
                                    </button>
                                  )
                                } else {
                                  return null
                                }
                              })}
                            </div>
                          </td>
                        )
                      } else if (k === 'colour') {
                        return (
                          <td key={'td' + index} className={styles.tableTd}>
                            <div className='score_wrapper'>
                              {item[k]}
                              <div className='score_color' style={{ background: item[k] }}/>
                            </div>
                          </td>
                        )
                      } else if (k === 'updatedDate' || k === 'createdDate') {
                        return (
                          <td key={'td' + index} className={styles.tableTd}>
                            {item[k]} <br/> <span className='date_info'>{moment(item[k]).fromNow()}</span>
                          </td>
                        )
                      } else {
                        const statusKey: string[] = k.split('#')

                        if (statusKey.length > 1) {
                          return (
                            <td
                              key={'key' + index}
                              className={styles.tableStatusTd}
                            >
                              {item[statusKey[1]] === true ? (
                                <Check
                                  size={16}
                                  className={styles.icon_green}
                                />
                              ) : (
                                <X size={16} className={styles.icon_red} />
                              )}
                            </td>
                          )
                        } else {
                          return (
                            <td key={'td' + index} className={styles.tableTd}>
                              {item[k]}
                            </td>
                          )
                        }
                      }
                    })}
                </tr>
              )
            })}
        </tbody>
      </table>
      <div className={styles.table_footer}>
        <div className={styles.footer_infos}>
          <div className={styles.show_rows}>
            <span>Show</span>
            <select value={limit} onChange={(e) => changeLimit(e)}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              {props.limitAll === true ? <option value={0}>ALL</option> : null}
            </select>
            <span>rows</span>
          </div>
        </div>
        <div className={styles.pagination}>
          <ul>
            <li
              onClick={props.prev}
              className={props.prevDisabled ? styles.disabled : ''}
            >
              <ChevronLeft size={20} />
              <span>Prev</span>
            </li>
            <li>
              <span>
                {props.totalData ? props.index * limit + 1 : 0} -{' '}
                {!props.nextDisabled
                  ? props.index * limit + limit
                  : props.totalData}{' '}
                of {props.totalData} rows
              </span>
            </li>
            <li
              onClick={props.next}
              className={props.nextDisabled ? styles.disabled : ''}
            >
              <span>Next</span>
              <ChevronRight size={20} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default DataTable
