import React, { useEffect, useState } from 'react'
import EligibilityForm from '../../components/Eligibility/EligibilityForm/EligibilityForm'
import EligibilityTable from '../../components/Eligibility/EligibilityTable/EligibilityTable'
import Header from '../../components/Header/Header'
import { useAppSelector } from '../../hooks/redux-hooks'
import Spinner from '../../components/Spinner/Spinner'
import Swal from 'sweetalert2'

import styles from '../styles/FormPage.module.css'
// import SearchData from '../../components/DataTable/SearchData/SearchData'

function Eligibility() {
  const eligibility = useAppSelector((state) => state.eligibility)

  const [showEligibility, SetShowEligibility] = useState(false)

  useEffect(() => {
    if (eligibility.addnew.loading === 'failed') {
      Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: eligibility.addnew.error ? eligibility.addnew.error : 'unknown'
      })
    }

    if (eligibility.addnew.loading === 'completed') {
      SetShowEligibility(false)
    }
  }, [eligibility])

  const hideForm = () => {
    SetShowEligibility(false)
  }

  return (
    <div className="wrapper">
      {eligibility.getall.loading === 'pending' ||
      eligibility.delete.loading === 'pending' ||
      eligibility.addnew.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <Header />
      <div className={styles.page_container}>
        <div className={styles.container}>
          <div className={styles.tableBody}>
            <div className={styles.tableTitle}>
              <div className={styles.title}>
                <h2 data-testid="eligibility-container-title">Eligibility</h2>
              </div>
              <div className={styles.right_buttons}>
                {/* <SearchData name="eligibility" /> */}
                <button onClick={() => SetShowEligibility(true)}>
                  Add New
                </button>
              </div>
            </div>
            <EligibilityTable />
          </div>
        </div>
      </div>
      {showEligibility ? <EligibilityForm hideForm={hideForm} /> : null}
    </div>
  )
}

export default Eligibility
