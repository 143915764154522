import React from 'react'
import styles from './Home.module.css'
import Header from '../../components/Header/Header'
// import Statistics from '../../components/Home/Statistics/Statistics'
// import LastUpdated from '../../components/Home/LastUpdated/LastUpdated'
// import History from '../../components/Home/History/History'

const pjson = require('../../../package.json')

function Home() {
  return (
    <div className="wrapper">
      <Header />
      <div className={styles.page_container}>
        <div className={styles.container}>
          <div className={styles.title}>
            <h2>ECP Back Office Portal</h2>
            <p>v{pjson.version}</p>
            <br/>
            <p>You are awesome today!</p>
          </div>
          {/* <div className={styles.statistics}>
            <Statistics
              dataIcon="customers"
              dataText={2456}
              dataLabel="Total customers"
            />
            <Statistics
              dataIcon="goodscore"
              dataText={1456}
              dataLabel="Based on good scores"
            />
            <Statistics
              dataIcon="badscore"
              dataText={1000}
              dataLabel="Based on bad scores"
            />
            <Statistics
              dataIcon="lastupdate"
              dataText={'2022-05-20 13:56'}
              dataLabel="Last scores updated at"
            />
            <Statistics
              dataIcon="lastsignin"
              dataText={'2022-05-20 10:56'}
              dataLabel="Last signed-in at"
            />
          </div>
          <div className={styles.historyContainer}>
            <div className={styles.lastUpdated}>
              <LastUpdated />
            </div>
            <div className={styles.history}>
              <History />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Home
