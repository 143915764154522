import React from 'react'
import ProgressBar from '@ramonak/react-progress-bar'
import styles from './Progress.module.css'

import {
  FileCheck,
  CheckupList,
  CloudUpload,
  Check,
  X
} from 'tabler-icons-react'

type ProgressState = {
  icon: string
  title: string
  content: string
  status: string
  process: number
  error: string | null
}

function Progress(props: ProgressState) {
  let icon = null

  if (props.icon === 'file-check') {
    icon = <FileCheck size={24} color="#f6f6f6" />
  }

  if (props.icon === 'data-check') {
    icon = <CheckupList size={24} color="#f6f6f6" />
  }

  if (props.icon === 'cloud-upload') {
    icon = <CloudUpload size={24} color="#f6f6f6" />
  }

  let status = <X className={styles.pending} size={24} color="#f5f5f5" />

  if (props.status === 'completed') {
    status = <Check className={styles.completed} size={24} color="Green" />
  }

  let containerStyle = null

  switch (props.status) {
    case 'failed':
      containerStyle = styles.failed_container

      break
    case 'completed':
      containerStyle = styles.completed_container

      break
    default:
      containerStyle = styles.idle_container
  }

  return (
    <div className={[styles.progress_container, containerStyle].join(' ')}>
      <div className={styles.progress_step}>
        <div className={styles.icon_step}>{icon}</div>
      </div>
      <div className={styles.progress_content}>
        <h2>{props.title}</h2>
        <ProgressBar
          height=".4rem"
          bgColor="#206bc4"
          labelAlignment="outside"
          labelColor="#206bc4"
          labelSize="1.2rem"
          completed={props.process}
        />
        <span>{props.content}</span>
      </div>
      <div className={styles.progress_status}>{status}</div>
    </div>
  )
}

export default Progress
