import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'
import DataTable from '../../DataTable/DataTable'
import { successAlert } from '../../../utils'
import {
  removeScore,
  fetchAllScore
} from '../../../store/services/Scores.services'
import { reset } from '../../../store/slices/Scores.slice'
import Spinner from '../../Spinner/Spinner'

function ScoreTable() {
  const dispatch = useAppDispatch()

  const [limit, setLimit] = useState<number>(0)

  const scores = useAppSelector((state) => state.scores)

  const [columns] = useState([
    // { text: '#', key: 'SK' },
    { text: 'Name', key: 'name' },
    { text: 'Label', key: 'label' },
    { text: 'Description', key: 'description' },
    { text: 'Colour', key: 'colour' },
    { text: 'Created Date', key: 'createdDate' },
    { text: 'Created By', key: 'updatedBy' },
    { text: '', key: '|remove' }
  ])

  useEffect(() => {
    if (scores.delete.loading === 'completed') {
      successAlert('The score data has been deleted', () => {
        dispatch(reset('delete'))
      })
      dispatch(fetchAllScore({}))
    }

    if (scores.addnew.loading === 'completed') {
      successAlert('The score data has been saved', () => {
        dispatch(reset('addnew'))
      })
      dispatch(fetchAllScore({}))
    }
  }, [scores.delete.loading, scores.addnew.loading])

  const removeScoreItem = (label: string) => {
    if (label.length > 0) {
      dispatch(removeScore({ label: label }))
    }
  }

  const viewScoreItem = (label: string) => {}

  const changeLimit = (limit: number) => {
    setLimit(limit)

    if (limit <= 0) {
      dispatch(fetchAllScore({}))
    } else {
      dispatch(fetchAllScore({ limit: limit }))
    }
  }

  const nextPagination = () => {
    if (scores.getall.navKeys.length > 0 && scores.getall.isNext === true) {
      const key = scores.getall.navKeys[scores.getall.navKeys.length - 1]

      dispatch(
        fetchAllScore({
          limit: limit,
          lastEvaluatedSK: key,
          type: 'next'
        })
      )
    }
  }

  const prevPagination = () => {
    if (scores.getall.navKeys.length > 0 && scores.getall.isPrev === true) {
      const key = scores.getall.navKeys[scores.getall.navKeys.length - 2]

      if (key === undefined) {
        dispatch(
          fetchAllScore({
            limit: limit
          })
        )
      } else {
        dispatch(
          fetchAllScore({
            limit: limit,
            lastEvaluatedSK: key,
            type: 'prev'
          })
        )
      }
    }
  }

  return (
    <React.Fragment>
      {scores.delete.loading === 'pending' ||
      scores.getdata.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <DataTable
        columns={columns}
        data={scores.getall.data}
        next={nextPagination}
        prev={prevPagination}
        prevDisabled={!scores.getall.isPrev}
        nextDisabled={!scores.getall.isNext}
        getLimitData={changeLimit}
        index={
          scores.getall.isNext
            ? scores.getall.navKeys && scores.getall.navKeys.length - 1
            : scores.getall.navKeys && scores.getall.navKeys.length
        }
        totalData={scores.getall.totalData}
        handleViewItem={viewScoreItem}
        handleRemoveItem={removeScoreItem}
        data_key="label"
      />
    </React.Fragment>
  )
}

export default ScoreTable
