import styles from './PageNotFound.module.css'
import { Auth } from 'aws-amplify'

function PageNotFound() {

  return (
    <div className="wrapper">
        <div className={styles.title}>
            <h1>Access to this page is restricted</h1>
            <p>Please check with the site admin if you believe this is a mistake.</p>
            <a href="mailto:azreensyazwani.mustapa@digi.com.my?Subject=ICS2.0%20Query">Contact Our Admin</a>
            <div
            onClick={() => Auth.signOut()}
                className={styles.logout_button}
              >
                <span>Go Back Home</span>
            </div>
        </div>
    </div>
  )
}

export default PageNotFound
