
import React, { useState, useContext } from 'react'
import useComponentVisible from '../../../hooks/useComponentVisible'
import { useNavigate } from 'react-router-dom'
import {
  Home2,
  SettingsAutomation,
  // Users,
  ChevronDown,
  Search,
  BookUpload
} from 'tabler-icons-react'

import { groups as cognitoGroups } from '../../../config/values'

import { AuthContext } from '../../../App'

import { useActiveMenu } from '../../../hooks/useActiveMenu'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'

import { getCustomerScoreDetails } from '../../../store/services/CustomerDetails.services'
import { reset } from '../../../store/slices/CustomerDetails.slice'

import Spinner from '../../Spinner/Spinner'

import styles from './Navbar.module.css'

function Navbar() {
  const authentication = useContext(AuthContext)

  const [refSubmenu, showSubmenu, setShowSubmenu] = useComponentVisible(false)

  const [search, setSearch] = useState('')

  const activeMenu = useActiveMenu()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const customer_details = useAppSelector((state) => state.customerDetails)

  const groups = authentication.user?.signInUserSession.accessToken.payload['cognito:groups'] || []

  const redirctTo = (url: string) => {
    navigate(url)
  }

  const handleSearchEnter = (e: any) => {
    if (e.key === 'Enter') {
      dispatch(reset('customer'))

      dispatch(getCustomerScoreDetails(search))
      // dispatch(getCustomerEligibleCombos({ msisdn: search }))

      navigate('/customer-details')
    }
  }

  let
    configSubmenu = null,
    configurationsMenu = null,
    fileUploaderMenu = null

  const
    isAdmin = groups.includes(cognitoGroups.icsAdmin),
    isConfigManager = groups.includes(cognitoGroups.icsConfigManager),
    isDigiFileUploader = groups.includes(cognitoGroups.icsDigiFileUploader),
    isIscoreFileUploader = groups.includes(cognitoGroups.icsExperianFileUploader)

  if (showSubmenu) {
    configSubmenu = (
      <div ref={refSubmenu} className={styles.dropdown_menu}>
        <ul>
          <li data-testid="mnu-criteria" onClick={() => redirctTo('/criteria')}>
            <span>Criteria</span>
          </li>
          <li
            data-testid="mnu-eligibility"
            onClick={() => redirctTo('/eligibility')}
          >
            <span>Eligibility</span>
          </li>
          <li
            data-testid="mnu-scores"
            onClick={() => redirctTo('/scores')}
          >
            <span>Scores</span>
          </li>
          <li
            data-testid="mnu-mapping"
            onClick={() => redirctTo('/mapping')}
          >
            <span>i-Score Mapping</span>
          </li>
          <li
            data-testid="mnu-combo"
            onClick={() => redirctTo('/combo')}
          >
            <span>Combo</span>
          </li>
          <li
            data-testid="mnu-products"
            onClick={() => redirctTo('/products')}
          >
            <span>Products</span>
          </li>
        </ul>
      </div>
    )
  }

  if (isAdmin || isConfigManager) {
    configurationsMenu = (
      <li
        data-testid="mnu-config"
        onClick={() => setShowSubmenu(true)}
        className={
          activeMenu.check('/criteria') ||
          activeMenu.check('/eligibility') ||
          activeMenu.check('/mapping') ||
          activeMenu.check('/scores') ||
          activeMenu.check('/combo') ||
          activeMenu.check('/products')
            ? styles.active
            : ''
        }
      >
        <div className={styles.nav_icon}>
          <SettingsAutomation size={20} color={'#545454'} />
        </div>
        <span>Configuration</span>
        <ChevronDown
          className={styles.dropdown_icon}
          size={13}
          color={'#545454'}
        />
        {configSubmenu}
      </li>
    )
  }

  if (isAdmin || isDigiFileUploader || isIscoreFileUploader) {
    fileUploaderMenu = (
      <li
        data-testid="mnu-upload-score"
        className={activeMenu.check('/upload-score') ? styles.active : ''}
        onClick={() => redirctTo('/upload-score')}
      >
        <div className={styles.nav_icon}>
          <BookUpload size={20} color={'#545454'} />
        </div>
        <span>Upload Score</span>
      </li>
    )
  }

  return (
    <div className={styles.navbar}>
      {customer_details.customer.loading === 'pending' ? <Spinner /> : null}
      <div className={styles.navbar_container}>
        <div className={styles.container}>
          <ul className={styles.navbar_nav}>
            <li
              data-testid="mnu-home"
              className={activeMenu.check('/') ? styles.active : ''}
              onClick={() => redirctTo('/')}
            >
              <div className={styles.nav_icon}>
                <Home2 size={20} color={'#545454'} />
              </div>
              <span>Home</span>
            </li>
            {configurationsMenu}
            {isAdmin && (
              <li
                data-testid="mnu-customers"
                className={activeMenu.check('/customers') ? styles.active : ''}
                onClick={() => redirctTo('/customers')}
              >
                <span>Customers</span>
              </li>
            )}
            {fileUploaderMenu}
          </ul>
          <div className={styles.navbar_search_box}>
            <span className={styles.navbar_search_icon}>
              <Search size={20} color={'#545454'} />
            </span>
            <input
              onKeyDown={(e) => handleSearchEnter(e)}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              name=""
              data-testid="navbar-search-input"
              id=""
              placeholder="Search customer with MSISDN / NRIC"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
