import Swal from 'sweetalert2'

import { prodADFSGroup } from '../config/values'

export const isFloatKey = (evt) => {
  const theEvent = evt || window.event

  let key = theEvent.keyCode || theEvent.which

  if (theEvent.type === 'paste') {
    key = theEvent.clipboardData.getData('text/plain')
  } else {
    key = String.fromCharCode(key)
  }

  const regex = /[0-9]|\./

  if (!regex.test(key)) {
    theEvent.returnValue = false

    if (theEvent.preventDefault) {
      theEvent.preventDefault()
    }
  }
}

export const isNumberKey = (evt) => {
  const theEvent = evt || window.event

  let key = theEvent.keyCode || theEvent.which

  if (theEvent.type === 'paste') {
    key = theEvent.clipboardData.getData('text/plain')
  } else {
    key = String.fromCharCode(key)
  }

  const regex = /[0-9]/

  if (!regex.test(key)) {
    theEvent.returnValue = false

    if (theEvent.preventDefault) {
      theEvent.preventDefault()
    }
  }
}

export const chunk = (arr, size) => {
  const chunkArray = []

  for (let i = 0; i < arr.length; i += size) {
    chunkArray.push(arr.slice(i, i + size))
  }

  return chunkArray
}

export const axiosHeaders = () => {
  return {
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('auth-token'),
      'Content-type': 'application/json; charset=UTF-8'
    }
  }
}

export const failiureAlert = (error, callback) => {
  Swal.fire({
    icon: 'error',
    title: 'Oops!',
    text: error ? error : 'unknown',
    willClose: () => {
      if (callback !== null) {
        callback(true)
      }
    }
  })
}

export function groupsToString(groups) {
  if (groups && groups.length > 0) {
    let groupsString = ''

    groups.forEach((group) => {
      if (group === prodADFSGroup) {
        return // skip, don't show in UI
      }

      if (groupsString) {
        groupsString += ', '
      }

      groupsString += group
    })

    return groupsString
  }

  return groups
}

export const successAlert = (text = '', callback) => {
  Swal.fire({
    title: 'Good Job!',
    text: text,
    icon: 'success',
    willClose: () => {
      callback(true)
    }
  })
}

export function isNumeric(str) {
  if (typeof str != 'string') {
    return false
  } else {
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ) // ...and ensure strings of whitespace fail
  }
}

export function clean(str, prefix = '') {
  let text = str.replace(/[^a-zA-Z0-9 ]/g, '')

  text = text.replace(/^\s\s*/, '').replace(/\s\s*$/, '')

  text = text.toLowerCase()

  text = text.replace(/\s\s+/g, ' ')

  text = text.replace(/[ ]/g, '_')

  return prefix + text
}

export function time_ago(time) {
  switch (typeof time) {
    case 'number':
      break
    case 'string':
      time = +new Date(time)

      break
    case 'object':
      if (time.constructor === Date) {
        time = time.getTime()
      }

      break
    default:
      time = +new Date()
  }

  const time_formats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ]

  let seconds = (+new Date() - time) / 1000,
    token = 'ago',
    list_choice = 1

  if (seconds === 0) {
    return 'Just now'
  }

  if (seconds < 0) {
    seconds = Math.abs(seconds)
    token = 'from now'
    list_choice = 2
  }

  let i = 0

  let format

  while ((format = time_formats[i++])) {
    if (seconds < format[0]) {
      if (typeof format[2] == 'string') {
        return format[list_choice]
      } else {
        return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token
      }
    }
  }

  return time
}

export function trimUsername(username) {
  if (!username || typeof username !== 'string') {
    return username
  }

  let newUsername = username.replace('digicorp-adfs_', '')

  newUsername = newUsername.replace('@digi.com.my', '')

  return newUsername
}

export function prefixText(prefix, text) {
  const newText = String(text)

  if (!newText.startsWith(prefix)) {
    return `${prefix}${newText}`
  }

  return text
}
