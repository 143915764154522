import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios.config'
import { axiosHeaders } from '../../utils'

export const fetchAllCriteria = createAsyncThunk<
  any,
  { limit?: number; lastEvaluatedSK?: string; type?: string },
  { rejectValue: string | null }
>('criteria/getalldata', async (params, thunkAPI) => {
  try {
    const response: any = await axios.get('/criteria/all', {
      ...axiosHeaders(),
      params: params
    })

    if (response.status === 200) {
      return {
        data: response.data.response.data,
        key: response.data.response.lastEvaluatedSK,
        total: response.data.response.totalDataCount,
        type: params.type ? params.type : ''
      }
    } else {
      return thunkAPI.rejectWithValue(response.meta.errorDescription)
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.response)
  }
})

export const saveCriteria = createAsyncThunk<
  any,
  {},
  { rejectValue: string | null }
>('criteria/save', async (params, thunkApi) => {
  try {
    const response: any = await axios.post(
      '/criteria/save',
      params,
      axiosHeaders()
    )

    if (response.status === 200 && response.data.response === 'success') {
      return { status: 'success' }
    } else {
      return thunkApi.rejectWithValue(response.data.meta.errorDescription)
    }
  } catch (error: any) {
    if (error.response.data.meta.errorDescription) {
      return thunkApi.rejectWithValue(error.response.data.meta.errorDescription)
    }

    return thunkApi.rejectWithValue(error.message)
  }
})

export const removeCriteria = createAsyncThunk<
  any,
  { label: string },
  { rejectValue: string | null }
>('criteria/delete', async (params, thunkApi) => {
  try {
    const response: any = await axios.delete(
      '/criteria/' + params.label,
      axiosHeaders()
    )

    if (response.status === 200 && response.data.response === 'success') {
      return { status: 'success' }
    } else {
      return thunkApi.rejectWithValue(response.data.meta.errorDescription)
    }
  } catch (error: any) {
    if (error.response.data.meta.errorDescription) {
      return thunkApi.rejectWithValue(error.response.data.meta.errorDescription)
    }

    return thunkApi.rejectWithValue(error.message)
  }
})

export const getCriteria = createAsyncThunk<
  any,
  { label: string; editMode: boolean },
  { rejectValue: string | null }
>('criteria/getdata', async (params, thunkAPI) => {
  try {
    const response: any = await axios.get(
      '/criteria/' + params.label,
      axiosHeaders()
    )

    if (response.status === 200) {
      return { data: response.data.response, editMode: params.editMode }
    } else {
      return thunkAPI.rejectWithValue(response.meta.errorDescription)
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.response)
  }
})
