import React, { useEffect, useState } from 'react'
import ProductForm from '../../components/Products/ProductForm/ProductForm'
import ProductTable from '../../components/Products/ProductTable/ProductTable'
import Header from '../../components/Header/Header'
import { useAppSelector } from '../../hooks/redux-hooks'
import Spinner from '../../components/Spinner/Spinner'
import Swal from 'sweetalert2'

import styles from '../styles/FormPage.module.css'
// import SearchData from '../../components/DataTable/SearchData/SearchData'

function Products() {
  const products = useAppSelector((state) => state.products)

  const [showProduct, SetShowProduct] = useState(false)

  useEffect(() => {
    if (products.addnew.loading === 'failed') {
      Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: products.addnew.error ? products.addnew.error : 'unknown'
      })
    }

    if (products.addnew.loading === 'completed') {
      SetShowProduct(false)
    }
  }, [products])

  const hideForm = () => {
    SetShowProduct(false)
  }

  return (
    <div className="wrapper">
      {products.getall.loading === 'pending' ||
      products.delete.loading === 'pending' ||
      products.addnew.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <Header />
      <div className={styles.page_container}>
        <div className={styles.container}>
          <div className={styles.tableBody}>
            <div className={styles.tableTitle}>
              <div className={styles.title}>
                <h2 data-testid="products-container-title">Products</h2>
              </div>
              <div className={styles.right_buttons}>
                {/* <SearchData name="products" /> */}
                <button onClick={() => SetShowProduct(true)}>Add New</button>
              </div>
            </div>
            <ProductTable />
          </div>
        </div>
      </div>
      {showProduct ? <ProductForm hideForm={hideForm} /> : null}
    </div>
  )
}

export default Products
