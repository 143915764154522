import React, { useEffect, useState, useContext } from 'react'
import { X, Check } from 'tabler-icons-react'
import moment from 'moment'
import Multiselect from 'multiselect-react-dropdown'

import { groups as cognitoGroups } from '../../../config/values'

import { AuthContext } from '../../../App'

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'

import { saveCombo, enableCombo, updateCombo } from '../../../store/services/Combo.services'
import { fetchAllScore } from '../../../store/services/Scores.services'
import { fetchAllProduct } from '../../../store/services/Products.services'
import { fetchAllCriteria } from '../../../store/services/Criteria.services'
import { fetchAllEligibility } from '../../../store/services/Eligibility.services'

import { clean, trimUsername } from '../../../utils/index'

import styles from '../../styles/FormComponent.module.css'

type TSelectValues = {
  name: string
  label: string
  description: string
  scores: string[]
  products: string[]
  criteria: string[]
  eligibilities: string[]
  enabled: boolean
}

type TSelectOptions = {
  scores: { key: string; name: string }[]
  products: { key: string; name: string }[]
  criteria: { key: string; name: string }[]
  eligibility: { key: string; name: string }[]
}
const initialInputValues: TSelectValues = {
  name: '',
  label: '',
  description: '',
  scores: [],
  products: [],
  criteria: [],
  eligibilities: [],
  enabled: false
}

type TProps = {
  hideForm: () => void
}

function ComboForm(props: TProps) {

  const authentication = useContext(AuthContext)

  const groups = authentication.user?.signInUserSession.accessToken.payload['cognito:groups'] || []

  const isAdmin = groups.includes(cognitoGroups.icsAdmin)

  const [inputs, setInputs] = useState(initialInputValues)

  const dispatch = useAppDispatch()

  /* load combo data */
  const combo = useAppSelector((state) => state.combo)

  const [selectOptions, setSelectOptions] = useState<TSelectOptions>({
    scores: [],
    products: [],
    criteria: [],
    eligibility: []
  })

  const scores = useAppSelector((state) => state.scores)

  const products = useAppSelector((state) => state.products)

  const criteria = useAppSelector((state) => state.criteria)

  const eligibility = useAppSelector((state) => state.eligibility)

  const [inputErrors, setInputErrors] = useState({
    ...initialInputValues
  })

  /* load dependancy data's */
  useEffect(() => {
    dispatch(fetchAllScore({}))
    dispatch(fetchAllProduct({}))
    dispatch(fetchAllCriteria({}))
    dispatch(fetchAllEligibility({}))
  }, [])

  /* set options to the multiselect component */
  useEffect(() => {
    const scoreOptions = scores.getall.data.map((item: any) => {
      return { key: item.SK, name: item.name }
    })

    const productOptions = products.getall.data.map((item: any) => {
      return { key: item.SK, name: item.name }
    })

    const criteriaOptions = criteria.getall.data.map((item: any) => {
      return { key: item.SK, name: item.name }
    })

    const eligibilityOptions = eligibility.getall.data.map((item: any) => {
      return { key: item.SK, name: item.name }
    })

    setSelectOptions({
      ...selectOptions,
      scores: scoreOptions,
      products: productOptions,
      criteria: criteriaOptions,
      eligibility: eligibilityOptions
    })
  }, [
    scores.getall.data,
    products.getall.data,
    criteria.getall.data,
    eligibility.getall.data
  ])

  /* edit form wih data's */
  useEffect(() => {
    if (combo.isEditMode === true) {
      setInputs({
        ...combo.getdata.data
      })
    }
  }, [combo.isEditMode])

  /* assign the value to state when input value change */
  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (e.target.name === 'name') {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
        label: clean(e.target.value, 'co_')
      })
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value })
    }
  }

  const handleSave = (type: string) => {
    let isValid = true
    let errors = {}

    errors = { ...initialInputValues }

    if (inputs.name.length < 1) {
      errors = { ...errors, name: 'Name is required' }
      isValid = false
    }

    if (inputs.label.length < 1) {
      errors = { ...errors, label: 'Label is required' }
      isValid = false
    }

    if (inputs.scores.length < 1) {
      errors = { ...errors, scores: 'Score is required' }
      isValid = false
    }

    if (inputs.products.length < 1) {
      errors = { ...errors, products: 'Product is required' }
      isValid = false
    }

    if (inputs.criteria.length < 1) {
      errors = { ...errors, criteria: 'Criteria is required' }
      isValid = false
    }

    if (inputs.eligibilities.length < 1) {
      errors = { ...errors, eligibilities: 'Eligibility is required' }
      isValid = false
    }

    setInputErrors({ ...inputErrors, ...errors })

    if (isValid === true) {
      let postParams = {}

      postParams = {
        name: inputs.name,
        label: inputs.label,
        description: inputs.description,
        scores: inputs.scores,
        products: inputs.products,
        criteria: inputs.criteria,
        eligibilities: inputs.eligibilities,
        enabled: inputs.enabled
      }

      dispatch(saveCombo(postParams))
    }
  }

  const handleUpdate = (type: string) => {
    let isValid = true
    let errors = {}

    errors = { ...initialInputValues }

    if (inputs.scores.length < 1) {
      errors = { ...errors, scores: 'Score is required' }
      isValid = false
    }

    if (inputs.products.length < 1) {
      errors = { ...errors, products: 'Product is required' }
      isValid = false
    }

    if (inputs.criteria.length < 1) {
      errors = { ...errors, criteria: 'Criteria is required' }
      isValid = false
    }

    if (inputs.eligibilities.length < 1) {
      errors = { ...errors, eligibilities: 'Eligibility is required' }
      isValid = false
    }

    setInputErrors({ ...inputErrors, ...errors })

    if (isValid === true) {
      let postParams = {}

      postParams = {
        description: inputs.description,
        scores: inputs.scores,
        products: inputs.products,
        criteria: inputs.criteria,
        eligibilities: inputs.eligibilities,
        enabled: inputs.enabled
      }

      dispatch(updateCombo({ label: inputs.label, params: postParams }))
      props.hideForm()
    }
  }

  const onSelectScore = (selectedList: any) => {
    setInputs({ ...inputs, scores: selectedList.map((item: any) => item.key) })
  }

  const onRemoveScore = (selectedList: any) => {
    setInputs({ ...inputs, scores: selectedList.map((item: any) => item.key) })
  }

  const onSelectProduct = (selectedList: any) => {
    setInputs({
      ...inputs,
      products: selectedList.map((item: any) => item.key)
    })
  }

  const onRemoveProduct = (selectedList: any) => {
    setInputs({
      ...inputs,
      products: selectedList.map((item: any) => item.key)
    })
  }

  const onSelectCriteria = (selectedList: any) => {
    setInputs({
      ...inputs,
      criteria: selectedList.map((item: any) => item.key)
    })
  }

  const onRemoveCriteria = (selectedList: any) => {
    setInputs({
      ...inputs,
      criteria: selectedList.map((item: any) => item.key)
    })
  }

  const onSelectEligibility = (selectedList: any) => {
    setInputs({
      ...inputs,
      eligibilities: selectedList.map((item: any) => item.key)
    })
  }

  const onRemoveEligibility = (selectedList: any) => {
    setInputs({
      ...inputs,
      eligibilities: selectedList.map((item: any) => item.key)
    })
  }

  const statusChangeItem = (label: string, status: string) => {
    if (label.length > 0 && status.length > 0) {
      dispatch(enableCombo({ label: label, status: status }))
    }
  }

  useEffect(() => {
    if (combo.enable.loading === 'completed') {
      props.hideForm()
    }
  }, [combo.enable.loading])

  return (
    <div className={styles.form_wrapper}>
      <div className={[styles.form_box, styles.combo_form_box].join(' ')}>
        <div className={styles.form_header}>
          <h2 className={styles.form_title}>
            {combo.isEditMode === true ? 'Update Combo Details' : 'Add New Combo'}
          </h2>
          <X size={22} onClick={() => props.hideForm()} />
        </div>
        <div className={styles.form}>
          <div className={styles.input_row}>
            { combo.isEditMode === true ? (
              <div className={styles.form_input}>
               <label htmlFor="name">Name</label>
               <input
                 readOnly
                 type="text"
                 autoComplete="off"
                 name="name"
                 id="name"
                 value={inputs.name}
                 onChange={(e) => handleInputChange(e)}
               />
               {inputErrors.name ? (
                 <span className={styles.input_error}>{inputErrors.name}</span>
               ) : null}
             </div>
            ) : (
              <div className={styles.form_input}>
               <label htmlFor="name">Name</label>
               <input
                 type="text"
                 autoComplete="off"
                 name="name"
                 id="name"
                 value={inputs.name}
                 onChange={(e) => handleInputChange(e)}
               />
               {inputErrors.name ? (
                 <span className={styles.input_error}>{inputErrors.name}</span>
               ) : null}
             </div>
            ) }
            <div className={styles.form_input}>
              <label htmlFor="name">Combo ID</label>
              <input
                readOnly
                type="text"
                autoComplete="off"
                name="label"
                id="label"
                value={inputs.label}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.label ? (
                <span className={styles.input_error}>{inputErrors.label}</span>
              ) : null}
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="name">Description</label>
              <input
                type="text"
                autoComplete="off"
                name="description"
                id="description"
                value={inputs.description}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="scores">Scores</label>
              <Multiselect
                disable={combo.getdata && combo.getdata.data.enabled === true ? true : false}
                hidePlaceholder={combo.isEditMode === true ? true : false}
                selectedValues={selectOptions.scores.filter((item) =>
                  inputs.scores.includes(item.key)
                )}
                onSelect={onSelectScore}
                onRemove={onRemoveScore}
                options={selectOptions.scores}
                className={styles._multiselect}
                displayValue="name"
                placeholder="Select scores..."
              />
              {inputErrors.scores ? (
                <span className={styles.input_error}>{inputErrors.scores}</span>
              ) : null}
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="products">Products</label>
              <Multiselect
                disable={combo.getdata && combo.getdata.data.enabled === true ? true : false}
                hidePlaceholder={combo.isEditMode === true ? true : false}
                displayValue="name"
                selectedValues={selectOptions.products.filter((item) =>
                  inputs.products.includes(item.key)
                )}
                onSelect={onSelectProduct}
                onRemove={onRemoveProduct}
                options={selectOptions.products}
                className={styles._multiselect}
                placeholder="Select products..."
              />
              {inputErrors.products ? (
                <span className={styles.input_error}>
                  {inputErrors.products}
                </span>
              ) : null}
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="criteria">Criteria</label>
              <Multiselect
                disable={combo.getdata && combo.getdata.data.enabled === true ? true : false}
                hidePlaceholder={combo.isEditMode === true ? true : false}
                options={selectOptions.criteria}
                displayValue="name"
                selectedValues={selectOptions.criteria.filter((item) =>
                  inputs.criteria.includes(item.key)
                )}
                onSelect={onSelectCriteria}
                onRemove={onRemoveCriteria}
                className={styles._multiselect}
                placeholder="Select criteria..."
              />
              {inputErrors.criteria ? (
                <span className={styles.input_error}>
                  {inputErrors.criteria}
                </span>
              ) : null}
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="eligibilities">Eligibility</label>
              <Multiselect
                disable={combo.getdata && combo.getdata.data.enabled === true ? true : false}
                hidePlaceholder={combo.isEditMode === true ? true : false}
                options={selectOptions.eligibility}
                selectedValues={selectOptions.eligibility.filter((item) =>
                  inputs.eligibilities.includes(item.key)
                )}
                onSelect={onSelectEligibility}
                onRemove={onRemoveEligibility}
                displayValue="name"
                className={styles._multiselect}
                placeholder="Select eligibilties..."
              />
              {inputErrors.eligibilities ? (
                <span className={styles.input_error}>
                  {inputErrors.eligibilities}
                </span>
              ) : null}
            </div>
          </div>
          {combo.isEditMode === true ? (
            <React.Fragment>
              <div
                className={[styles.input_row, styles.readonly_group].join(' ')}
              >
                <div className={styles.form_input}>
                  <label>Updated Date</label>
                  <input
                    type="text"
                    value={combo.getdata.data.updatedDate}
                    readOnly={true}
                  />
                  <span className='input_date_info'>{moment(combo.getdata.data.updatedDate).fromNow()}</span>
                </div>
                <div className={styles.form_input}>
                  <label>Updated By</label>
                  <input
                    type="text"
                    value={trimUsername(combo.getdata.data.updatedBy)}
                    readOnly={true}
                  />
                </div>
              </div>
              { isAdmin ? (
                <div className={styles.status_combo}>
                <button
                  className={
                    combo.getdata && combo.getdata.data.enabled === true
                      ? styles.enabledbtn
                      : styles.disabledbtn
                  }
                  onClick={() =>
                    statusChangeItem(
                      combo.getdata && combo.getdata.data.label,
                      combo.getdata && combo.getdata.data.enabled === true
                        ? 'disable'
                        : 'enable'
                    )
                  }
                >
                  {combo.getdata && combo.getdata.data.enabled === true ? (
                    <div>
                      <X size={20} /> <span>Disable this Combo</span>
                    </div>
                  ) : (
                    <React.Fragment>
                    <div>
                      <Check size={20} /> <span>Enable this Combo</span>
                    </div>
                   </React.Fragment>
                  )}
                </button>
              </div>
              ) : null }
            </React.Fragment>
          ) : null}
        </div>
        <div className={styles.input_row}>
        {combo.isEditMode === false ? (
          <div className={styles.form_button}>
            <span className={styles.error_message}></span>
            <button
              type="button"
              onClick={() => {
                handleSave('save')
              }}
            >
              Save
            </button>
          </div>
        ) : null }
        { combo.isEditMode === true && combo.getdata && combo.getdata.data.enabled === false ? (
          <div className={styles.form_button}>
          <span className={styles.error_message}></span>
          <button
            type="button"
            onClick={() => {
              handleUpdate('update')
            }}
          >
            Update
          </button>
        </div>
        ) : null }
        </div>
      </div>
    </div>
  )
}

export default ComboForm
