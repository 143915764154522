import React, { useContext } from 'react'

// import { Bell, Logout } from 'tabler-icons-react'
import { Logout } from 'tabler-icons-react'
import { Auth } from 'aws-amplify'

import { AuthContext } from '../../../App'

import { groupsToString } from '../../../utils'

import styles from './HeaderTop.module.css'

import CelcomDigiLogo from '../../../assets/images/celcom-digi.png'
import Logo from '../../../assets/images/logo.png'
import NoUser from '../../../assets/images/no-user.png'

function HeaderTop() {
  const authentication = useContext(AuthContext)

  return (
    <div className={styles.header_top}>
      <div className={styles.container}>
        <div className={styles.header_top_container}>
          <div className={styles.logo}>
            <img src={CelcomDigiLogo} alt="CelcomDigi" style={{ borderRadius: '10px', marginLeft: '0px' }} />
            <img style={{ marginLeft: '0.5rem' }} src={Logo} alt="ECP" />
            <h2 style={{ marginLeft: '1rem' }}>ECP</h2>
          </div>
          <div className={styles.right_menu}>
            <ul>
              {/* <li>
                <Bell size={26} color={'#545454'} />
              </li> */}
              <li className={styles.auth_info}>
                <div className={styles.auth_info_picture}>
                  <img src={NoUser} alt="User" />
                </div>
                <div className={styles.user_name}>
                  <h2>
                    {
                      authentication.user !== null
                        ? authentication.user?.username
                        : 'Guest'
                    }
                  </h2>
                  <span>
                    { groupsToString(authentication.user?.signInUserSession.accessToken.payload['cognito:groups']) }
                  </span>
                </div>
              </li>
              <li
                onClick={() => Auth.signOut()}
                className={styles.logout_button}
              >
                <Logout size={24} color={'#545454'} />
                <span>Logout</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderTop
