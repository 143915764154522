/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'
import DataTable from '../../DataTable/DataTable'
import { successAlert } from '../../../utils'
import {
  removeCustomer
  // fetchAllCustomer
} from '../../../store/services/Customers.services'
import { reset } from '../../../store/slices/Customers.slice'
import Spinner from '../../Spinner/Spinner'

function CustomerTable() {
  const dispatch = useAppDispatch()

  const [limit, setLimit] = useState<number>(0)

  const customers = useAppSelector((state) => state.customers)

  const [columns] = useState([
    { text: '#', key: 'SK' },
    { text: 'MSISDN', key: 'msisdn' },
    { text: 'Customer ID', key: 'customerId' },
    { text: 'Score Type', key: 'scoreType' },
    { text: 'Score', key: 'score' },
    { text: 'Tenure (days)', key: 'tenure' },
    { text: 'ARPU (RM)', key: 'ARPU' },
    { text: 'Commitment Value (RM)', key: 'CV' },
    { text: 'Updated Medium', key: 'updateMedium' },
    { text: 'Updated Date', key: 'updatedDate' },
    { text: 'Updated By', key: 'updatedBy' },
    { text: '', key: '|remove' }
  ])

  useEffect(() => {
    if (customers.delete.loading === 'completed') {
      successAlert('The customer data has been deleted', () => {
        dispatch(reset('delete'))
      })
      // dispatch(fetchAllCustomer({}))
    }

    if (customers.addnew.loading === 'completed') {
      successAlert('The customer data has been saved', () => {
        dispatch(reset('addnew'))
      })
      // dispatch(fetchAllCustomer({}))
    }
  }, [customers.delete.loading, customers.addnew.loading])

  const removeCustomerItem = (label: string) => {
    if (label.length > 0) {
      dispatch(removeCustomer({ label: encodeURIComponent(label) }))
    }
  }

  const viewCustomerItem = (label: string) => {}

  // const changeLimit = (limit: number) => {
  //   setLimit(limit)

  //   if (limit <= 0) {
  //     // dispatch(fetchAllCustomer({}))
  //   } else {
  //     // dispatch(fetchAllCustomer({ limit: limit }))
  //   }
  // }

  const nextPagination = () => {
    if (
      customers.getall.navKeys.length > 0 &&
      customers.getall.isNext === true
    ) {
      const key = customers.getall.navKeys[customers.getall.navKeys.length - 1]

      // dispatch(
      //   fetchAllCustomer({
      //     limit: limit,
      //     lastEvaluatedSK: key,
      //     type: 'next'
      //   })
      // )
    }
  }

  // const prevPagination = () => {
  //   if (
  //     customers.getall.navKeys.length > 0 &&
  //     customers.getall.isPrev === true
  //   ) {
  //     const key = customers.getall.navKeys[customers.getall.navKeys.length - 2]

  //     if (key === undefined) {
  //       // dispatch(
  //       //   fetchAllCustomer({
  //       //     limit: limit
  //       //   })
  //       // )
  //     } else {
  //       // dispatch(
  //       //   fetchAllCustomer({
  //       //     limit: limit,
  //       //     lastEvaluatedSK: key,
  //       //     type: 'prev'
  //       //   })
  //       // )
  //     }
  //   }
  // }

  return (
    <React.Fragment>
      {customers.delete.loading === 'pending' ||
      customers.getdata.loading === 'pending' ? (
        <Spinner />
      ) : null}
      {/* <DataTable
        columns={columns}
        data={customers.getall.data}
        next={nextPagination}
        prev={prevPagination}
        prevDisabled={!customers.getall.isPrev}
        nextDisabled={!customers.getall.isNext}
        getLimitData={changeLimit}
        totalData={customers.getall.totalData}
        data_key="SK"
        index={
          customers.getall.isNext
            ? customers.getall.navKeys && customers.getall.navKeys.length - 1
            : customers.getall.navKeys && customers.getall.navKeys.length
        }
        limitAll={false}
        handleViewItem={viewCustomerItem}
        handleRemoveItem={removeCustomerItem}
      /> */}
    </React.Fragment>
  )
}

export default CustomerTable
