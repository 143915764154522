import React, { useState } from 'react'
import { X } from 'tabler-icons-react'
import { useAppDispatch } from '../../../hooks/redux-hooks'
import { saveProduct } from '../../../store/services/Products.services'

import styles from '../../styles/FormComponent.module.css'

const initialInputValues = {
  name: '',
  label: '',
  description: ''
}

type TProps = {
  hideForm: () => void
}

function ProductForm(props: TProps) {
  const [inputs, setInputs] = useState(initialInputValues)

  const dispatch = useAppDispatch()

  const [inputErrors, setInputErrors] = useState({
    ...initialInputValues
  })

  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const handleSave = (type: string) => {
    let isValid = true
    let errors = {}

    errors = { ...initialInputValues }

    if (inputs.name.length < 1) {
      errors = { ...errors, name: 'Name is required' }
      isValid = false
    }

    if (inputs.label.length < 1) {
      errors = { ...errors, label: 'Label is required' }
      isValid = false
    }

    setInputErrors({ ...inputErrors, ...errors })

    if (isValid === true) {
      let postParams = {}

      postParams = {
        name: inputs.name,
        label: inputs.label,
        description: inputs.description
      }

      dispatch(saveProduct(postParams))
    }
  }

  return (
    <div className={styles.form_wrapper}>
      <div className={styles.form_box}>
        <div className={styles.form_header}>
          <h2 className={styles.form_title}>Add New Product</h2>
          <X size={22} onClick={() => props.hideForm()} />
        </div>
        <div className={styles.form}>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                autoComplete="off"
                name="name"
                id="name"
                value={inputs.name}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.name ? (
                <span className={styles.input_error}>{inputErrors.name}</span>
              ) : null}
            </div>
            <div className={styles.form_input}>
              <label htmlFor="name">Product SKU</label>
              <input
                type="text"
                autoComplete="off"
                name="label"
                id="label"
                value={inputs.label}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.label ? (
                <span className={styles.input_error}>{inputErrors.label}</span>
              ) : null}
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="name">Description</label>
              <input
                type="text"
                autoComplete="off"
                name="description"
                id="description"
                value={inputs.description}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
        </div>
        <div className={styles.form_button}>
          <span className={styles.error_message}></span>
          <button
            type="button"
            onClick={() => {
              handleSave('save')
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductForm
