import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios.config'
import { axiosHeaders } from '../../utils'

export const fetchAllMapping = createAsyncThunk<
  any,
  { limit?: number; lastEvaluatedSK?: string; type?: string },
  { rejectValue: string | null }
>('mapping/getalldata', async (params, thunkAPI) => {
  try {
    const response: any = await axios.get('/mapping/all', {
      ...axiosHeaders(),
      params: params
    })

    if (response.status === 200) {
      return {
        data: response.data.response.data,
        key: response.data.response.lastEvaluatedSK,
        total: response.data.response.totalDataCount,
        type: params.type ? params.type : ''
      }
    } else {
      return thunkAPI.rejectWithValue(response.meta.errorDescription)
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.response)
  }
})

export const saveMapping = createAsyncThunk<
  any,
  {},
  { rejectValue: string | null }
>('mapping/save', async (params, thunkApi) => {
  try {
    const response: any = await axios.post(
      '/mapping/save',
      params,
      axiosHeaders()
    )

    if (response.status === 200 && response.data.response === 'success') {
      return { status: 'success' }
    } else {
      return thunkApi.rejectWithValue(response.data.meta.errorDescription)
    }
  } catch (error: any) {
    if (error.response.data.meta.errorDescription) {
      return thunkApi.rejectWithValue(error.response.data.meta.errorDescription)
    }

    return thunkApi.rejectWithValue(error.message)
  }
})

export const removeMapping = createAsyncThunk<
  any,
  { label: string },
  { rejectValue: string | null }
>('mapping/delete', async (params, thunkApi) => {
  try {
    const response: any = await axios.delete(
      '/mapping/' + params.label,
      axiosHeaders()
    )

    if (response.status === 200 && response.data.response === 'success') {
      return { status: 'success' }
    } else {
      return thunkApi.rejectWithValue(response.data.meta.errorDescription)
    }
  } catch (error: any) {
    if (error.response.data.meta.errorDescription) {
      return thunkApi.rejectWithValue(error.response.data.meta.errorDescription)
    }

    return thunkApi.rejectWithValue(error.message)
  }
})

export const updateMapping = createAsyncThunk<
  any,
  { label: string; params: object },
  { rejectValue: string | null }
>('mapping/update', async (params, thunkApi) => {
  try {
    const response: any = await axios.post(
      '/mapping/update/' + params.label,
      params.params,
      axiosHeaders()
    )

    if (response.status === 200 && response.data.response === 'success') {
      return { status: 'success' }
    } else {
      return thunkApi.rejectWithValue(response.data.meta.errorDescription)
    }
  } catch (error: any) {
    if (error.response.data.meta.errorDescription) {
      return thunkApi.rejectWithValue(error.response.data.meta.errorDescription)
    }

    return thunkApi.rejectWithValue(error.message)
  }
})

export const getMapping = createAsyncThunk<
  any,
  { label: string; editMode: boolean },
  { rejectValue: string | null }
>('mapping/getdata', async (params, thunkAPI) => {
  try {
    const response: any = await axios.get(
      '/mapping/' + params.label,
      axiosHeaders()
    )

    if (response.status === 200) {
      return { data: response.data.response, editMode: params.editMode }
    } else {
      return thunkAPI.rejectWithValue(response.meta.errorDescription)
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.response)
  }
})
