import React from 'react'
import { Rings } from 'react-loader-spinner'
import styles from './Spinner.module.css'

function Spinner() {
  return (
    <div className={styles.spinner_container}>
      <Rings color="#006ce9" width={150} height={150} />
    </div>
  )
}

export default Spinner
