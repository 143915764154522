import React, { useEffect, useState } from 'react'
import { X } from 'tabler-icons-react'
import { useAppDispatch } from '../../../hooks/redux-hooks'
import { saveEligibility } from '../../../store/services/Eligibility.services'
import { isFloatKey, clean } from '../../../utils'
import { eligibilityFieldTypes } from '../../../config/constants'

import styles from '../../styles/FormComponent.module.css'

const initialInputValues = {
  name: '',
  label: '',
  description: '',
  fieldName: '',
  fieldType: 'discountAmount',
  amount: ''
}

type TProps = {
  hideForm: () => void
}

function EligibilityForm(props: TProps) {
  const [inputs, setInputs] = useState(initialInputValues)

  const dispatch = useAppDispatch()

  const [inputErrors, setInputErrors] = useState({
    ...initialInputValues,
    fieldType: ''
  })

  useEffect(() => {
    setInputs({ ...inputs, amount: '' })
  }, [inputs.fieldType])

  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (e.target.name === 'name') {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
        label: clean(e.target.value, 'el_')
      })
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value })
    }
  }

  const handleSave = (type: string) => {
    let isValid = true
    let errors = {}

    errors = { ...initialInputValues }

    if (inputs.name.length < 1) {
      errors = { ...errors, name: 'Name is required' }
      isValid = false
    }

    if (inputs.label.length < 1) {
      errors = { ...errors, label: 'Label is required' }
      isValid = false
    }

    if (inputs.fieldName.length < 1) {
      errors = { ...errors, fieldName: 'Field name is required' }
      isValid = false
    }

    if (inputs.fieldType !== 'freebieFlag') {
      if (isNaN(parseInt(inputs.amount)) || parseInt(inputs.amount) < 0) {
        errors = { ...errors, amount: 'Field value is required' }
        isValid = false
      }
    }

    setInputErrors({ ...inputErrors, ...errors })

    if (isValid === true) {
      let postParams = {}

      postParams = {
        name: inputs.name,
        label: inputs.label,
        description: inputs.description,
        fieldName: inputs.fieldName,
        fieldType: inputs.fieldType
      }

      if (inputs.fieldType !== 'freebieFlag') {
        postParams = { ...postParams, amount: parseInt(inputs.amount) }
      }

      dispatch(saveEligibility(postParams))
    }
  }

  return (
    <div className={styles.form_wrapper}>
      <div className={styles.form_box}>
        <div className={styles.form_header}>
          <h2 className={styles.form_title}>Add New Eligibility</h2>
          <X size={22} onClick={() => props.hideForm()} />
        </div>
        <div className={styles.form}>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                autoComplete="off"
                name="name"
                id="name"
                value={inputs.name}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.name ? (
                <span className={styles.input_error}>{inputErrors.name}</span>
              ) : null}
            </div>
            <div className={styles.form_input}>
              <label htmlFor="name">Eligibility ID</label>
              <input
                readOnly
                type="text"
                autoComplete="off"
                name="label"
                id="label"
                value={inputs.label}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.label ? (
                <span className={styles.input_error}>{inputErrors.label}</span>
              ) : null}
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="name">Description</label>
              <input
                type="text"
                autoComplete="off"
                name="description"
                id="description"
                value={inputs.description}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="fieldName">Field Name</label>
              <input
                type="text"
                autoComplete="off"
                name="fieldName"
                value={inputs.fieldName}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.fieldName ? (
                <span className={styles.input_error}>
                  {inputErrors.fieldName}
                </span>
              ) : null}
            </div>
            <div className={styles.form_input}>
              <label htmlFor="name">Field Type</label>
              <select
                name="fieldType"
                onChange={(e) => handleInputChange(e)}
                value={inputs.fieldType}
              >
                {eligibilityFieldTypes.map((item) => {
                  return (
                    <option key={item.key} value={item.key}>
                      {item.value}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="name">Field Value</label>
              <input
                type="text"
                autoComplete="off"
                name="amount"
                onKeyPress={(e) => isFloatKey(e)}
                value={inputs.amount}
                disabled={inputs.fieldType === 'freebieFlag' ? true : false}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.amount ? (
                <span className={styles.input_error}>{inputErrors.amount}</span>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.form_button}>
          <span className={styles.error_message}></span>
          <button
            type="button"
            onClick={() => {
              handleSave('save')
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default EligibilityForm
