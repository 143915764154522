import React, { useState } from 'react'
import { X } from 'tabler-icons-react'
import { useAppDispatch } from '../../../hooks/redux-hooks'

import { digiScorePrefix } from '../../../config/constants'

import { saveScore } from '../../../store/services/Scores.services'

import { prefixText } from '../../../utils/index'

import styles from '../../styles/FormComponent.module.css'

const initialInputValues = {
  name: '',
  label: digiScorePrefix,
  description: '',
  colour: ''
}

const intialErrorValues = {
  ...initialInputValues,
  label: ''
}

type TProps = {
  hideForm: () => void
}

function ScoreForm(props: TProps) {
  const [inputs, setInputs] = useState(initialInputValues)

  const dispatch = useAppDispatch()

  const [inputErrors, setInputErrors] = useState(intialErrorValues)

  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    let newValue = e.target.value

    if (e.target.name === 'label') {
      newValue = prefixText(digiScorePrefix, newValue)
    }

    setInputs({ ...inputs, [e.target.name]: newValue })
  }

  const handleSave = (type: string) => {
    let isValid = true
    let errors = {}

    errors = intialErrorValues

    if (inputs.name.length < 1) {
      errors = { ...errors, name: 'Name is required' }
      isValid = false
    }

    if (inputs.label === digiScorePrefix) {
      errors = { ...errors, label: 'Label is required' }
      isValid = false
    }

    setInputErrors({ ...inputErrors, ...errors })

    if (isValid === true) {
      let postParams = {}

      postParams = {
        name: inputs.name,
        label: inputs.label,
        description: inputs.description,
        colour: inputs.colour
      }

      dispatch(saveScore(postParams))
    }
  }

  return (
    <div className={styles.form_wrapper}>
      <div className={styles.form_box}>
        <div className={styles.form_header}>
          <h2 className={styles.form_title}>Add New Score</h2>
          <X size={22} onClick={() => props.hideForm()} />
        </div>
        <div className={styles.form}>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                autoComplete="off"
                name="name"
                id="name"
                value={inputs.name}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.name ? (
                <span className={styles.input_error}>{inputErrors.name}</span>
              ) : null}
            </div>
            <div className={styles.form_input}>
              <label htmlFor="name">Score Label</label>
              <input
                type="text"
                autoComplete="off"
                name="label"
                id="label"
                value={inputs.label}
                onChange={(e) => handleInputChange(e)}
              />
              <div className={styles.input_description}>Score label will be prefixed with '{digiScorePrefix}'.</div>
              {inputErrors.label ? (
                <span className={styles.input_error}>{inputErrors.label}</span>
              ) : null}
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="name">Description</label>
              <input
                type="text"
                autoComplete="off"
                name="description"
                id="description"
                value={inputs.description}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className={styles.form_input}>
              <label htmlFor="colour">Colour</label>
              <input
                type="color"
                autoComplete="off"
                name="colour"
                id="colour"
                value={inputs.colour}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
        </div>
        <div className={styles.form_button}>
          <span className={styles.error_message}></span>
          <button
            type="button"
            onClick={() => {
              handleSave('save')
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default ScoreForm
