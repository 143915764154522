import React, { useContext } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { groups as cognitoGroups } from './config/values'

import { AuthContext } from './App'

import Home from './containers/Home/Home'
import Criteria from './containers/Criteria/Criteria'
import UploadScore from './containers/UploadScore/UploadScore'
import Eligibility from './containers/Eligibility/Eligibility'
import Products from './containers/Products/Products'
import Customers from './containers/Customers/Customers'
import Scores from './containers/Scores/Scores'
import Mapping from './containers/Mapping/Mapping'
import Combo from './containers/Combo/Combo'
import CustomerDetails from './containers/CustomerDetails/CustomerDetails'
import PageNotFound from './containers/PageNotFound/PageNotFound'

function Router() {
  const authentication = useContext(AuthContext)

  const groups = authentication.user?.signInUserSession.accessToken.payload['cognito:groups'] || []

  const
    isAdmin = groups.includes(cognitoGroups.icsAdmin),
    isConfigManager = groups.includes(cognitoGroups.icsConfigManager),
    isDigiFileUploader = groups.includes(cognitoGroups.icsDigiFileUploader),
    isIscoreFileUploader = groups.includes(cognitoGroups.icsExperianFileUploader)

  const getProtectedRoute = (isAuthorized: boolean, path: string, Component: any) => {
    const NewComponent = isAuthorized ? Component : PageNotFound

    return (<Route path={path} element={<NewComponent />} />)
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        { getProtectedRoute(isAdmin || isConfigManager, '/criteria', Criteria) }
        { getProtectedRoute(isAdmin || isConfigManager, '/eligibility', Eligibility) }
        { getProtectedRoute(isAdmin || isConfigManager, '/scores', Scores) }
        { getProtectedRoute(isAdmin || isConfigManager, '/mapping', Mapping) }
        { getProtectedRoute(isAdmin || isConfigManager, '/products', Products) }
        { getProtectedRoute(isAdmin || isConfigManager, '/combo', Combo) }
        { getProtectedRoute(isAdmin || isDigiFileUploader || isIscoreFileUploader, '/upload-score', UploadScore) }
        <Route path="/customers" element={<Customers />} />
        <Route path="/customer-details" element={<CustomerDetails />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
