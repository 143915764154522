import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'
import DataTable from '../../DataTable/DataTable'
import { successAlert } from '../../../utils'
import {
  removeProduct,
  fetchAllProduct
} from '../../../store/services/Products.services'
import { reset } from '../../../store/slices/Products.slice'
import Spinner from '../../Spinner/Spinner'

function ProductTable() {
  const dispatch = useAppDispatch()

  const [limit, setLimit] = useState<number>(0)

  const products = useAppSelector((state) => state.products)

  const [columns] = useState([
    // { text: '#', key: 'SK' },
    { text: 'Name', key: 'name' },
    { text: 'Product SKU', key: 'label' },
    { text: 'Description', key: 'description' },
    { text: 'Created Date', key: 'createdDate' },
    { text: 'Created By', key: 'updatedBy' },
    { text: '', key: '|remove' }
  ])

  useEffect(() => {
    if (products.delete.loading === 'completed') {
      successAlert('The product data has been deleted', () => {
        dispatch(reset('delete'))
      })
      dispatch(fetchAllProduct({}))
    }

    if (products.addnew.loading === 'completed') {
      successAlert('The product data has been saved', () => {
        dispatch(reset('addnew'))
      })
      dispatch(fetchAllProduct({}))
    }
  }, [products.delete.loading, products.addnew.loading])

  const removeProductItem = (label: string) => {
    if (label.length > 0) {
      dispatch(removeProduct({ label: label }))
    }
  }

  const viewProductItem = (label: string) => {}

  const changeLimit = (limit: number) => {
    setLimit(limit)

    if (limit <= 0) {
      dispatch(fetchAllProduct({}))
    } else {
      dispatch(fetchAllProduct({ limit: limit }))
    }
  }

  const nextPagination = () => {
    if (products.getall.navKeys.length > 0 && products.getall.isNext === true) {
      const key = products.getall.navKeys[products.getall.navKeys.length - 1]

      dispatch(
        fetchAllProduct({
          limit: limit,
          lastEvaluatedSK: key,
          type: 'next'
        })
      )
    }
  }

  const prevPagination = () => {
    if (products.getall.navKeys.length > 0 && products.getall.isPrev === true) {
      const key = products.getall.navKeys[products.getall.navKeys.length - 2]

      if (key === undefined) {
        dispatch(
          fetchAllProduct({
            limit: limit
          })
        )
      } else {
        dispatch(
          fetchAllProduct({
            limit: limit,
            lastEvaluatedSK: key,
            type: 'prev'
          })
        )
      }
    }
  }

  return (
    <React.Fragment>
      {products.delete.loading === 'pending' ||
      products.getdata.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <DataTable
        columns={columns}
        data={products.getall.data}
        next={nextPagination}
        prev={prevPagination}
        prevDisabled={!products.getall.isPrev}
        nextDisabled={!products.getall.isNext}
        getLimitData={changeLimit}
        index={
          products.getall.isNext
            ? products.getall.navKeys && products.getall.navKeys.length - 1
            : products.getall.navKeys && products.getall.navKeys.length
        }
        totalData={products.getall.totalData}
        handleViewItem={viewProductItem}
        handleRemoveItem={removeProductItem}
        data_key="label"
      />
    </React.Fragment>
  )
}

export default ProductTable
