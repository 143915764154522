import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'

import { useAppSelector, useAppDispatch } from '../../hooks/redux-hooks'

import ComboForm from '../../components/Combo/ComboForm/ComboForm'
import ComboTable from '../../components/Combo/ComboTable/ComboTable'
import Header from '../../components/Header/Header'
import Spinner from '../../components/Spinner/Spinner'

import { reset } from '../../store/slices/Combo.slice'

import styles from '../styles/FormPage.module.css'

function Combo() {
  const combo = useAppSelector((state) => state.combo)

  const dispatch = useAppDispatch()

  const [showCombo, SetShowCombo] = useState(false)

  useEffect(() => {
    if (combo.addnew.loading === 'failed') {
      Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: combo.addnew.error ? combo.addnew.error : 'unknown'
      })
    }

    if (combo.addnew.loading === 'completed') {
      SetShowCombo(false)
    }
  }, [combo])

  useEffect(() => {
    if (combo.isEditMode === true) {
      SetShowCombo(true)
    }
  }, [combo.isEditMode])

  const hideForm = () => {
    if (combo.isEditMode === true) {
      dispatch(reset('getdata'))
    }

    SetShowCombo(false)
  }

  return (
    <div className="wrapper">
      {combo.getall.loading === 'pending' ||
      combo.delete.loading === 'pending' ||
      combo.addnew.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <Header />
      <div className={styles.page_container}>
        <div className={styles.container}>
          <div className={styles.tableBody}>
            <div className={styles.tableTitle}>
              <div className={styles.title}>
                <h2 data-testid="combo-container-title">Combo</h2>
              </div>
              <div className={styles.right_buttons}>
                {/* <SearchData name="combo" /> */}
                <button onClick={() => SetShowCombo(true)}>Add New</button>
              </div>
            </div>
            <ComboTable />
          </div>
          {showCombo ? <ComboForm hideForm={hideForm} /> : null}
        </div>
      </div>
    </div>
  )
}

export default Combo
