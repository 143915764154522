import { createSlice } from '@reduxjs/toolkit'

import {
  getCustomerEligibleCombos,
  getCustomerScoreDetails,
  getDigiHistory,
  getExperianHistory,
  getDigiScoreDetails,
  getFinalScoreDetails,
  updateScore
} from '../services/CustomerDetails.services'

type TBaseData = {
  loading: 'idle' | 'pending' | 'failed' | 'completed'
  error: null | string | undefined
  data: any
}

type TInitialState = {
  customer: TBaseData
  eligibleCombos: TBaseData
  updateScore: TBaseData
  digiHistory: TBaseData
  expHistory: TBaseData
  digiScoreDetails: TBaseData
  finalScoreDetails: TBaseData
}
const initialStateData: TBaseData = {
  loading: 'idle',
  error: null,
  data: []
}

const initialState: TInitialState = {
  customer: initialStateData,
  eligibleCombos: initialStateData,
  updateScore: initialStateData,
  digiHistory: initialStateData,
  expHistory: initialStateData,
  digiScoreDetails: initialStateData,
  finalScoreDetails: initialStateData
}

const customerDetails = createSlice({
  name: 'customer-details',
  initialState,
  reducers: {
    reset: (state, { payload }) => {
      switch (payload) {
        case 'customer':
          state.customer = initialStateData

          break
        case 'eligibleCombos':
          state.eligibleCombos = initialStateData

          break
        case 'digiHistory':
          state.digiHistory = initialStateData

          break

        case 'expHistory':
          state.expHistory = initialStateData

          break

        case 'updateScore':
          state.updateScore = initialStateData

          break

        case 'digiScoreDetails':
          state.digiScoreDetails = initialStateData

          break
        case 'finalScoreDetails':
          state.finalScoreDetails = initialStateData

          break
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerScoreDetails.pending, (state) => {
        state.customer.loading = 'pending'
        state.customer.error = null
        state.customer.data = []
      })
      .addCase(getCustomerScoreDetails.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.customer.loading = 'completed'
          state.customer.data = payload.data
          state.customer.error = null
        }
      })
      .addCase(getCustomerScoreDetails.rejected, (state, { payload }) => {
        state.customer.loading = 'failed'
        state.customer.data = []
        state.customer.error = payload
      })

    builder
      .addCase(getCustomerEligibleCombos.pending, (state) => {
        state.eligibleCombos.loading = 'pending'
        state.eligibleCombos.error = null
        state.eligibleCombos.data = []
      })
      .addCase(getCustomerEligibleCombos.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.eligibleCombos.loading = 'completed'
          state.eligibleCombos.data = payload.data
          state.eligibleCombos.error = null
        }
      })
      .addCase(getCustomerEligibleCombos.rejected, (state, { payload }) => {
        state.eligibleCombos.loading = 'failed'
        state.eligibleCombos.data = []
        state.eligibleCombos.error = payload
      })

    builder
      .addCase(getDigiHistory.pending, (state) => {
        state.digiHistory.loading = 'pending'
        state.digiHistory.error = null
        state.digiHistory.data = []
      })
      .addCase(getDigiHistory.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.digiHistory.loading = 'completed'
          state.digiHistory.data = payload.data
          state.digiHistory.error = null
        }
      })
      .addCase(getDigiHistory.rejected, (state, { payload }) => {
        state.digiHistory.loading = 'failed'
        state.digiHistory.data = []
        state.digiHistory.error = payload
      })

    builder
      .addCase(getExperianHistory.pending, (state) => {
        state.expHistory.loading = 'pending'
        state.expHistory.error = null
        state.expHistory.data = []
      })
      .addCase(getExperianHistory.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.expHistory.loading = 'completed'
          state.expHistory.data = payload.data
          state.expHistory.error = null
        }
      })
      .addCase(getExperianHistory.rejected, (state, { payload }) => {
        state.expHistory.loading = 'failed'
        state.expHistory.data = []
        state.expHistory.error = payload
      })

    builder
      .addCase(updateScore.pending, (state) => {
        state.updateScore.loading = 'pending'
        state.updateScore.error = null
        state.updateScore.data = []
      })
      .addCase(updateScore.fulfilled, (state, { payload }) => {
        if (payload) {
          state.updateScore.loading = 'completed'
          state.updateScore.error = null
        }
      })
      .addCase(updateScore.rejected, (state, { payload }) => {
        state.updateScore.loading = 'failed'
        state.updateScore.data = []
        state.updateScore.error = payload
      })

    builder
      .addCase(getDigiScoreDetails.pending, (state) => {
        state.digiScoreDetails.loading = 'pending'
        state.digiScoreDetails.error = null
        state.digiScoreDetails.data = []
      })
      .addCase(getDigiScoreDetails.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.digiScoreDetails.loading = 'completed'
          state.digiScoreDetails.data = payload.data
          state.digiScoreDetails.error = null
        }
      })
      .addCase(getDigiScoreDetails.rejected, (state, { payload }) => {
        state.digiScoreDetails.loading = 'failed'
        state.digiScoreDetails.data = []
        state.digiScoreDetails.error = payload
      })

    builder
      .addCase(getFinalScoreDetails.pending, (state) => {
        state.finalScoreDetails.loading = 'pending'
        state.finalScoreDetails.error = null
        state.finalScoreDetails.data = []
      })
      .addCase(getFinalScoreDetails.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.finalScoreDetails.loading = 'completed'
          state.finalScoreDetails.data = payload.data
          state.finalScoreDetails.error = null
        }
      })
      .addCase(getFinalScoreDetails.rejected, (state, { payload }) => {
        state.finalScoreDetails.loading = 'failed'
        state.finalScoreDetails.data = []
        state.finalScoreDetails.error = payload
      })
  }
})

export const { reset } = customerDetails.actions

export default customerDetails.reducer
