import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios.config'
import { axiosHeaders } from '../../utils'

export const uploadScore = createAsyncThunk<
  { status: string },
  { rows: number; csvdata: any; scoreType: string; },
  { rejectValue: string | null }
>('upload/fetch_url', async (data, thunkApi) => {
  try {
    const response = await axios.get(
      `/feedFile/upload/url?fileType=csv&rowCount=${data.rows}&scoreType=${data.scoreType}`,
      axiosHeaders()
    )

    if (response && response.status === 200) {
      const upload_url = response.data.response

      if (upload_url) {
        const csvContent = data.csvdata
          .map(function (d: any) {
            return JSON.stringify(Object.values(d))
          })
          .join('\n')
          .replace(/(^\[)|(\]$)/gm, '')

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

        await axios.put(upload_url, blob, {
          headers: {
            'Content-Type': 'text/csv'
          }
        })

        return { status: 'completed' }
      } else {
        return thunkApi.rejectWithValue('Something went wrong')
      }
    } else {
      return thunkApi.rejectWithValue('Something went wrong')
    }
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response.data.response)
  }
})
