import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'
import DataTable from '../../DataTable/DataTable'
import { successAlert } from '../../../utils'
import {
  removeCriteria,
  fetchAllCriteria,
  getCriteria
} from '../../../store/services/Criteria.services'
import { reset } from '../../../store/slices/Criteria.slice'
import Spinner from '../../Spinner/Spinner'

function CriteriaTable() {
  const dispatch = useAppDispatch()

  const [limit, setLimit] = useState<number>(0)

  const criteria = useAppSelector((state) => state.criteria)

  const [columns] = useState([
    // { text: '#', key: 'SK' },
    { text: 'Name', key: 'name' },
    // { text: 'Label', key: 'label' },
    { text: 'Criteria Type', key: 'criteriaType' },
    { text: 'Field Name', key: 'fieldName' },
    { text: 'Field Type', key: 'fieldType' },
    { text: '', key: 'view|remove' }
  ])

  useEffect(() => {
    if (criteria.delete.loading === 'completed') {
      successAlert('The criteria data has been deleted', () => {
        dispatch(reset('delete'))
      })
      dispatch(fetchAllCriteria({}))
    }

    if (criteria.addnew.loading === 'completed') {
      successAlert('The criteria data has been saved', () => {
        dispatch(reset('addnew'))
      })
      dispatch(fetchAllCriteria({}))
    }
  }, [criteria.delete.loading, criteria.addnew.loading])

  const removeCriteriaItem = (label: string) => {
    if (label.length > 0) {
      dispatch(removeCriteria({ label: label }))
    }
  }

  const viewCriteriaItem = (label: string) => {
    if (label.length > 0) {
      dispatch(getCriteria({ label: label, editMode: true }))
    }
  }

  const changeLimit = (limit: number) => {
    setLimit(limit)

    if (limit <= 0) {
      dispatch(fetchAllCriteria({}))
    } else {
      dispatch(fetchAllCriteria({ limit: limit }))
    }
  }

  const nextPagination = () => {
    if (criteria.getall.navKeys.length > 0 && criteria.getall.isNext === true) {
      const key = criteria.getall.navKeys[criteria.getall.navKeys.length - 1]

      dispatch(
        fetchAllCriteria({
          limit: limit,
          lastEvaluatedSK: key,
          type: 'next'
        })
      )
    }
  }

  const prevPagination = () => {
    if (criteria.getall.navKeys.length > 0 && criteria.getall.isPrev === true) {
      const key = criteria.getall.navKeys[criteria.getall.navKeys.length - 2]

      if (key === undefined) {
        dispatch(
          fetchAllCriteria({
            limit: limit
          })
        )
      } else {
        dispatch(
          fetchAllCriteria({
            limit: limit,
            lastEvaluatedSK: key,
            type: 'prev'
          })
        )
      }
    }
  }

  return (
    <React.Fragment>
      {criteria.delete.loading === 'pending' ||
      criteria.getdata.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <DataTable
        columns={columns}
        data={criteria.getall.data}
        next={nextPagination}
        prev={prevPagination}
        prevDisabled={!criteria.getall.isPrev}
        nextDisabled={!criteria.getall.isNext}
        getLimitData={changeLimit}
        index={
          criteria.getall.isNext
            ? criteria.getall.navKeys && criteria.getall.navKeys.length - 1
            : criteria.getall.navKeys && criteria.getall.navKeys.length
        }
        totalData={criteria.getall.totalData}
        handleViewItem={viewCriteriaItem}
        handleRemoveItem={removeCriteriaItem}
        data_key="label"
      />
    </React.Fragment>
  )
}

export default CriteriaTable
