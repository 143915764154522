import React from 'react'

import styles from './FileUploadRules.module.css'

type FileUploadRulesProps = { scoreType: string }

const csvFileRule: string = 'The file content must be comma-separated and in .csv format.'

function FileUploadRules(props: FileUploadRulesProps) {
  const { scoreType } = props

  if (scoreType === 'DIGI' || scoreType === 'CELCOM') {
    return (
      <div>
        <div className={styles.rules_container}>
          <strong>{scoreType} score customer file rules:</strong>
          <ol>
            <li>{csvFileRule}</li>
            <li>Columns / Headers: <pre>SUBS_MSISDN,LN_SUBS_ID,SCORE,TENURE,ARPU,CF</pre></li>
            <li>SUBS_MSISDN and SCORE are required. The score must be available/created first in Configuration &gt; Score menu.</li>
            <li>LN_SUBS_ID, TENURE, ARPU and CF are optional: Leave it empty if there is no value for any of these attributes.</li>
          </ol>
        </div>
        <div className={styles.rules_container} >
          <strong>Sample file content:</strong>
          <pre className={styles.sample_data_container}>
            SUBS_MSISDN,LN_SUBS_ID,SCORE,TENURE,ARPU,CF<br/>
	          60173876779,6183213,9,10,10,<br/>
            60173876781,,1,,,9<br/>
          </pre>
        </div>
      </div>
    )
  }

  if (scoreType === 'CD') {
    return (
      <div>
        <div className={styles.rules_container}>
          <strong>{scoreType} score customer file rules:</strong>
          <ol>
            <li>{csvFileRule}</li>
            <li>Columns / Headers: <pre>SUBS_MSISDN,LN_SUBS_ID,SCORE,TENURE,ARPU,CF,TELCO,CUSTOMER_ID_NUMBER</pre></li>
            <li>TELCO, CUSTOMER_ID_NUMBER, SUBS_MSISDN and SCORE are required. The score must be available/created first in Configuration &gt; Score menu.</li>
            <li>LN_SUBS_ID, TENURE, ARPU and CF are optional: Leave it empty if there is no value for any of these attributes.</li>
          </ol>
        </div>
        <div className={styles.rules_container} >
          <strong>Sample file content:</strong>
          <pre className={styles.sample_data_container}>
          SUBS_MSISDN,LN_SUBS_ID,SCORE,TENURE,ARPU,CF,TELCO,CUSTOMER_ID_NUMBER<br/>
	          60173876779,61832134,1140,60,60,Digi,79012306XXXX<br/>
            60173876781,,1,,,9,Celcom,80012306XXXX<br/>
          </pre>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className={styles.rules_container}>
        <strong>i-Score customer file rules:</strong>
        <ol>
          <li>The file content must be comma-separated and in .csv format.</li>
          <li>Columns / Headers: <pre>CUSTOMER_ID,SUBS_MSISDN,SCORE</pre></li>
          <li>Only one of CUSTOMER_ID or SUBS_MSISDN <strong>value</strong> is required for every line of records.</li>
          <li>SCORE is required. Make sure the scores are in the range of the mappings configured in Configuration &gt;  i-Score Mapping menu.</li>
        </ol>
      </div>
      <div className={styles.rules_container}>
        <strong>Sample file content:</strong>
        <pre className={styles.sample_data_container}>
          CUSTOMER_ID,SUBS_MSISDN,SCORE<br/>
	        91010111010,,900<br/>
          ,60123456789,690
        </pre>
      </div>
    </div>
  )
}

export default FileUploadRules
