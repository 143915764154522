import React, { useEffect, useState } from 'react'
import { X } from 'tabler-icons-react'
import moment from 'moment'

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks'
import { saveMapping, updateMapping } from '../../../store/services/Mapping.services'
import { fetchAllScore } from '../../../store/services/Scores.services'
import { isFloatKey, clean, trimUsername } from '../../../utils/index'
import { rangeTypes } from '../../../config/constants'
import styles from '../../styles/FormComponent.module.css'

const initialInputValues = {
  name: '',
  label: '',
  description: '',
  digiScore: '',
  rangeType: 'inBetween',
  textValues: '',
  min_value: '',
  max_value: ''
}

type TProps = {
  hideForm: () => void
}

function MappingForm(props: TProps) {
  const [inputs, setInputs] = useState(initialInputValues)

  const dispatch = useAppDispatch()

  const mapping = useAppSelector((state) => state.mapping)

  const scores = useAppSelector((state) => state.scores)

  const [inputErrors, setInputErrors] = useState({
    ...initialInputValues
  })

  useEffect(() => {
    dispatch(fetchAllScore({}))
  }, [])

  useEffect(() => {
    if (mapping.isEditMode === true) {
      setInputs({
        ...mapping.getdata.data,
        min_value: mapping.getdata.data.rangeValue?.min,
        max_value: mapping.getdata.data.rangeValue?.max
      })
    }
  }, [mapping.isEditMode])

  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (e.target.name === 'name') {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
        label: clean(e.target.value, 'em_')
      })
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value })
    }
  }

  const handleUpdate = (type: string) => {
    let isValid = true
    let errors = {}

    errors = { ...initialInputValues }

    if (inputs.name.length < 1) {
      errors = { ...errors, name: 'Name is required' }
      isValid = false
    }

    if (inputs.label.length < 1) {
      errors = { ...errors, label: 'Label is required' }
      isValid = false
    }

    if (inputs.digiScore.length < 1) {
      errors = { ...errors, digiScore: 'Digi Score is required' }
      isValid = false
    }

    if (inputs.rangeType === 'lessThan') {
      if (
        isNaN(parseInt(inputs.max_value)) ||
        parseInt(inputs.max_value) <= 0
      ) {
        errors = { ...errors, max_value: 'Max value is required' }
        isValid = false
      }
    }

    if (inputs.rangeType === 'moreThan') {
      if (isNaN(parseInt(inputs.min_value)) || parseInt(inputs.min_value) < 0) {
        errors = { ...errors, min_value: 'Min value is required' }
        isValid = false
      }
    }

    if (inputs.rangeType === 'inBetween') {
      if (isNaN(parseInt(inputs.min_value)) || parseInt(inputs.min_value) < 0) {
        errors = { ...errors, min_value: 'Min value is required' }
        isValid = false
      }

      if (
        isNaN(parseInt(inputs.max_value)) ||
        parseInt(inputs.max_value) <= 0
      ) {
        errors = { ...errors, max_value: 'Max value is required' }
        isValid = false
      }
    }

    setInputErrors({ ...inputErrors, ...errors })

    if (isValid === true) {
      let postParams = {}

      postParams = {
        description: inputs.description,
        digiScore: inputs.digiScore,
        rangeType: inputs.rangeType
      }

      if (inputs.rangeType === 'inBetween') {
        postParams = {
          ...postParams,
          rangeValue: {
            min: parseInt(inputs.min_value.toString()),
            max: parseInt(inputs.max_value.toString())
          }
        }
      }

      if (inputs.rangeType === 'lessThan') {
        postParams = {
          ...postParams,
          rangeValue: { max: parseInt(inputs.max_value.toString()) }
        }
      }

      if (inputs.rangeType === 'moreThan') {
        postParams = {
          ...postParams,
          rangeValue: { min: parseInt(inputs.min_value.toString()) }
        }
      }

      dispatch(updateMapping({ label: inputs.label, params: postParams }))

      props.hideForm()
    }
  }

  const handleSave = (type: string) => {
    let isValid = true
    let errors = {}

    errors = { ...initialInputValues }

    if (inputs.name.length < 1) {
      errors = { ...errors, name: 'Name is required' }
      isValid = false
    }

    if (inputs.label.length < 1) {
      errors = { ...errors, label: 'Label is required' }
      isValid = false
    }

    if (inputs.digiScore.length < 1) {
      errors = { ...errors, digiScore: 'Digi Score is required' }
      isValid = false
    }

    if (inputs.rangeType === 'lessThan') {
      if (
        isNaN(parseInt(inputs.max_value)) ||
        parseInt(inputs.max_value) <= 0
      ) {
        errors = { ...errors, max_value: 'Max value is required' }
        isValid = false
      }
    }

    if (inputs.rangeType === 'moreThan') {
      if (isNaN(parseInt(inputs.min_value)) || parseInt(inputs.min_value) < 0) {
        errors = { ...errors, min_value: 'Min value is required' }
        isValid = false
      }
    }

    if (inputs.rangeType === 'inBetween') {
      if (isNaN(parseInt(inputs.min_value)) || parseInt(inputs.min_value) < 0) {
        errors = { ...errors, min_value: 'Min value is required' }
        isValid = false
      }

      if (
        isNaN(parseInt(inputs.max_value)) ||
        parseInt(inputs.max_value) <= 0
      ) {
        errors = { ...errors, max_value: 'Max value is required' }
        isValid = false
      }
    }

    setInputErrors({ ...inputErrors, ...errors })

    if (isValid === true) {
      let postParams = {}

      postParams = {
        name: inputs.name,
        label: inputs.label,
        description: inputs.description,
        digiScore: inputs.digiScore,
        rangeType: inputs.rangeType
      }

      if (inputs.rangeType === 'inBetween') {
        postParams = {
          ...postParams,
          rangeValue: {
            min: parseInt(inputs.min_value.toString()),
            max: parseInt(inputs.max_value.toString())
          }
        }
      }

      if (inputs.rangeType === 'lessThan') {
        postParams = {
          ...postParams,
          rangeValue: { max: parseInt(inputs.max_value.toString()) }
        }
      }

      if (inputs.rangeType === 'moreThan') {
        postParams = {
          ...postParams,
          rangeValue: { min: parseInt(inputs.min_value.toString()) }
        }
      }

      dispatch(saveMapping(postParams))
    }
  }

  return (
    <div className={styles.form_wrapper}>
      <div className={styles.form_box}>
        <div className={styles.form_header}>
          <h2 className={styles.form_title}>
            { mapping.isEditMode === true ? 'Update i-Score Mapping Details' : 'Add new i-Score Mapping' }
          </h2>
          <X size={22} onClick={() => props.hideForm()} />
        </div>
        <div className={styles.form}>
          <div className={styles.input_row}>
            { mapping.isEditMode === true ? (
               <div className={styles.form_input}>
               <label htmlFor="name">Name</label>
               <input
                 readOnly
                 type="text"
                 autoComplete="off"
                 name="name"
                 id="name"
                 value={inputs.name}
                 onChange={(e) => handleInputChange(e)}
               />
               {inputErrors.name ? (
                 <span className={styles.input_error}>{inputErrors.name}</span>
               ) : null}
             </div>
            ) : (
              <div className={styles.form_input}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                autoComplete="off"
                name="name"
                id="name"
                value={inputs.name}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.name ? (
                <span className={styles.input_error}>{inputErrors.name}</span>
              ) : null}
            </div>
            ) }
            <div className={styles.form_input}>
              <label htmlFor="name">Mapping ID</label>
              <input
                readOnly
                type="text"
                autoComplete="off"
                name="label"
                id="label"
                value={inputs.label}
                onChange={(e) => handleInputChange(e)}
              />
              {inputErrors.label ? (
                <span className={styles.input_error}>{inputErrors.label}</span>
              ) : null}
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="name">Description</label>
              <input
                type="text"
                autoComplete="off"
                name="description"
                id="description"
                value={inputs.description}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className={styles.input_row}>
            <div className={styles.form_input}>
              <label htmlFor="digiScore">Digi Score</label>
              <select
                name="digiScore"
                onChange={(e) => handleInputChange(e)}
                value={inputs.digiScore}
              >
                <option value="">- select -</option>
                {scores.getall.data.map((item: any) => {
                  return (
                    <option key={item.SK} value={item.label}>
                      {item.name} ({item.label})
                    </option>
                  )
                })}
              </select>
              {inputErrors.digiScore ? (
                <span className={styles.input_error}>
                  {inputErrors.digiScore}
                </span>
              ) : null}
            </div>
            <div className={styles.form_input}>
              <label htmlFor="name">Range Type</label>
              <select
                name="rangeType"
                onChange={(e) => handleInputChange(e)}
                value={inputs.rangeType}
              >
                {rangeTypes.map((item) => {
                  return (
                    <option key={item.key} value={item.key}>
                      {item.value}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className={styles.input_row}>
            {['moreThan', 'inBetween'].indexOf(inputs.rangeType) > -1 ? (
              <div className={styles.form_input}>
                <label htmlFor="name">Min Value</label>
                <input
                  type="text"
                  autoComplete="off"
                  name="min_value"
                  value={inputs.min_value}
                  onKeyPress={(e) => isFloatKey(e)}
                  onChange={(e) => handleInputChange(e)}
                />
                {inputErrors.min_value ? (
                  <span className={styles.input_error}>
                    {inputErrors.min_value}
                  </span>
                ) : null}
              </div>
            ) : null}
            {['lessThan', 'inBetween'].indexOf(inputs.rangeType) > -1 ? (
              <div className={styles.form_input}>
                <label htmlFor="name">Max Value</label>
                <input
                  type="text"
                  autoComplete="off"
                  name="max_value"
                  onKeyPress={(e) => isFloatKey(e)}
                  value={inputs.max_value}
                  onChange={(e) => handleInputChange(e)}
                />
                {inputErrors.max_value ? (
                  <span className={styles.input_error}>
                    {inputErrors.max_value}
                  </span>
                ) : null}
              </div>
            ) : null}
          </div>

          {mapping.isEditMode === true ? (
            <React.Fragment>
              <div className={styles.divider} />
              <div
                className={[styles.input_row, styles.readonly_group].join(' ')}
              >
                <div className={styles.form_input}>
                  <label>Updated Date</label>
                  <input
                    type="text"
                    value={mapping.getdata.data.updatedDate}
                    readOnly={true}
                  />
                  <span className='input_date_info'>{moment(mapping.getdata.data.updatedDate).fromNow()}</span>
                </div>
                <div className={styles.form_input}>
                  <label>Updated By</label>
                  <input
                    type="text"
                    value={trimUsername(mapping.getdata.data.updatedBy)}
                    readOnly={true}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </div>
        {mapping.isEditMode === false ? (
          <div className={styles.form_button}>
            <span className={styles.error_message}></span>
            <button
              type="button"
              onClick={() => {
                handleSave('save')
              }}
            >
              Save
            </button>
          </div>
        ) : <div className={styles.form_button}>
        <span className={styles.error_message}></span>
        <button
          type="button"
          onClick={() => {
            handleUpdate('update')
          }}
        >
          Update
        </button>
      </div>}
      </div>
    </div>
  )
}

export default MappingForm
