import React, { useEffect, useState } from 'react'
import MappingForm from '../../components/Mapping/MappingForm/MappingForm'
import MappingTable from '../../components/Mapping/MappingTable/MappingTable'
import Header from '../../components/Header/Header'
import { useAppSelector, useAppDispatch } from '../../hooks/redux-hooks'
import Spinner from '../../components/Spinner/Spinner'
import Swal from 'sweetalert2'
import { reset } from '../../store/slices/Mapping.slice'
import styles from '../styles/FormPage.module.css'
// import SearchData from '../../components/DataTable/SearchData/SearchData'

function Mapping() {
  const mapping = useAppSelector((state) => state.mapping)

  const dispatch = useAppDispatch()

  const [showMapping, SetShowMapping] = useState(false)

  useEffect(() => {
    if (mapping.addnew.loading === 'failed') {
      Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: mapping.addnew.error ? mapping.addnew.error : 'unknown'
      })
    }

    if (mapping.addnew.loading === 'completed') {
      SetShowMapping(false)
    }
  }, [mapping])

  useEffect(() => {
    if (mapping.isEditMode === true) {
      SetShowMapping(true)
    }
  }, [mapping.isEditMode])

  const hideForm = () => {
    if (mapping.isEditMode === true) {
      dispatch(reset('getdata'))
    }

    SetShowMapping(false)
  }

  return (
    <div className="wrapper">
      {mapping.getall.loading === 'pending' ||
      mapping.delete.loading === 'pending' ||
      mapping.addnew.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <Header />
      <div className={styles.page_container}>
        <div className={styles.container}>
          <div className={styles.tableBody}>
            <div className={styles.tableTitle}>
              <div className={styles.title}>
                <h2 data-testid="mapping-container-title">i-Score Mapping</h2>
              </div>
              <div className={styles.right_buttons}>
                {/* <SearchData name="experian mapping" /> */}
                <button onClick={() => SetShowMapping(true)}>Add New</button>
              </div>
            </div>
            <MappingTable />
          </div>
        </div>
      </div>
      {showMapping ? <MappingForm hideForm={hideForm} /> : null}
    </div>
  )
}

export default Mapping
