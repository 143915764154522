import React from 'react'
import moment from 'moment'

import { trimUsername } from '../../../utils/index'

import styles from './History.module.css'

type TProps = {
  data: any
}

const cleanHistory = (item: any, index: number) => {
  return (
    <li key={index}>
      <p>
        {item.scoreType} score - {item.score} {item.action}d {' '} by <strong>{trimUsername(item.updatedBy) || item.updateMedium}</strong> {item.remarks && ` with remarks "${item.remarks}"`}
      </p>
      <span>{item.updatedDate}</span>
      <span className={styles.date_info}>{moment(item.updatedDate).fromNow()}</span>
    </li>
  )
}

function History({ data }: TProps) {
  return (
    <div className={styles.historyContainer}>
      <div className={styles.title}>
        <h2 data-testid="recent-history-title">History</h2>
      </div>
      <div className={styles.historyBody}>
        <ul>
          {
            data?.length ? data.map((item: any, index: number) => {
              return cleanHistory(item, index)
            }) : (
              <li>
                <p>
                  Loading history...
                </p>
              </li>
            )
          }
        </ul>
      </div>
    </div>
  )
}

export default History
