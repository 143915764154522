import { configureStore } from '@reduxjs/toolkit'
import UploadScoreReducer from './slices/UploadScore.slice'
import CriteriaReducer from './slices/Criteria.slice'
import EligibilityReducer from './slices/Eligibility.slice'
import ProductsReducer from './slices/Products.slice'
import CustomerReducer from './slices/Customers.slice'
import ScoreReducer from './slices/Scores.slice'
import MappingReducer from './slices/Mapping.slice'
import ComboReducer from './slices/Combo.slice'
import CustomerDetailsReducer from './slices/CustomerDetails.slice'

const store = configureStore({
  reducer: {
    uploadScore: UploadScoreReducer,
    criteria: CriteriaReducer,
    eligibility: EligibilityReducer,
    products: ProductsReducer,
    customers: CustomerReducer,
    scores: ScoreReducer,
    mapping: MappingReducer,
    combo: ComboReducer,
    customerDetails: CustomerDetailsReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
