import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../config/axios.config'
import { axiosHeaders } from '../../utils'

export const fetchAllScore = createAsyncThunk<
  any,
  { limit?: number; lastEvaluatedSK?: string; type?: string },
  { rejectValue: string | null }
>('score/getall', async (params, thunkAPI) => {
  try {
    const response: any = await axios.get('/score/all', {
      ...axiosHeaders(),
      params: params
    })

    if (response.status === 200) {
      return {
        data: response.data.response.data,
        key: response.data.response.lastEvaluatedSK,
        total: response.data.response.totalDataCount,
        type: params.type ? params.type : ''
      }
    } else {
      return thunkAPI.rejectWithValue(response.meta.errorDescription)
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.response)
  }
})

export const saveScore = createAsyncThunk<
  any,
  {},
  { rejectValue: string | null }
>('score/save', async (params, thunkApi) => {
  try {
    const response: any = await axios.post(
      '/score/save',
      params,
      axiosHeaders()
    )

    if (response.status === 200 && response.data.response === 'success') {
      return { status: 'success' }
    } else {
      return thunkApi.rejectWithValue(response.data.meta.errorDescription)
    }
  } catch (error: any) {
    if (error.response.data.meta.errorDescription) {
      return thunkApi.rejectWithValue(error.response.data.meta.errorDescription)
    }

    return thunkApi.rejectWithValue(error.message)
  }
})

export const removeScore = createAsyncThunk<
  any,
  { label: string },
  { rejectValue: string | null }
>('score/delete', async (params, thunkApi) => {
  try {
    const response: any = await axios.delete(
      '/score/' + params.label,
      axiosHeaders()
    )

    if (response.status === 200 && response.data.response === 'success') {
      return { status: 'success' }
    } else {
      return thunkApi.rejectWithValue(response.data.meta.errorDescription)
    }
  } catch (error: any) {
    if (error.response.data.meta.errorDescription) {
      return thunkApi.rejectWithValue(error.response.data.meta.errorDescription)
    }

    return thunkApi.rejectWithValue(error.message)
  }
})

export const getScore = createAsyncThunk<
  any,
  { label: string },
  { rejectValue: string | null }
>('score/getdata', async (params, thunkAPI) => {
  try {
    const response: any = await axios.get(
      '/score/' + params.label,
      axiosHeaders()
    )

    if (response.status === 200) {
      return { data: response.data.response }
    } else {
      return thunkAPI.rejectWithValue(response.meta.errorDescription)
    }
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.response)
  }
})
