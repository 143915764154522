export const groups = {
  icsAdmin: 'ics-Admin',
  icsConfigManager: 'ics-ConfigManager',
  icsDigiFileUploader: 'ics-DigiFileUploader',
  icsExperianFileUploader: 'ics-ExperianFileUploader',
  icsManualCreditScore: 'ics-ManualCreditScore'
}

export const prodADFSGroup = 'ap-southeast-1_A8Tz746oj_DIGICORP-ADFS'

export const scoreTypes = {
  digi: 'DIGI',
  experian: 'i-SCORE',
  celcom: 'CELCOM',
  cd: 'CD'
}
