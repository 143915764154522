import React, { useEffect, useState } from 'react'
import CustomerForm from '../../components/Customers/CustomerForm/CustomerForm'
import CustomerTable from '../../components/Customers/CustomerTable/CustomerTable'
import Header from '../../components/Header/Header'
import { useAppSelector } from '../../hooks/redux-hooks'
import Spinner from '../../components/Spinner/Spinner'
import Swal from 'sweetalert2'

import styles from '../styles/FormPage.module.css'
// import SearchData from '../../components/DataTable/SearchData/SearchData'

function Customers() {
  const customers = useAppSelector((state) => state.customers)

  const [showCustomer, SetShowCustomer] = useState(false)

  useEffect(() => {
    if (customers.addnew.loading === 'failed') {
      Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: customers.addnew.error ? customers.addnew.error : 'unknown'
      })
    }

    if (customers.addnew.loading === 'completed') {
      SetShowCustomer(false)
    }
  }, [customers])

  const hideForm = () => {
    SetShowCustomer(false)
  }

  return (
    <div className="wrapper">
      {customers.getall.loading === 'pending' ||
      customers.delete.loading === 'pending' ||
      customers.addnew.loading === 'pending' ? (
        <Spinner />
      ) : null}
      <Header />
      <div className={styles.page_container}>
        <div className={styles.container}>
          <div className={styles.tableBody}>
            <div className={styles.tableTitle}>
              <div className={styles.title}>
                <h2 data-testid="customers-container-title">Customers</h2>
              </div>
              <div className={styles.right_buttons}>
                {/* <SearchData name="customers" /> */}
                <button onClick={() => SetShowCustomer(true)}>Add New</button>
              </div>
            </div>
            <CustomerTable />
          </div>
        </div>
      </div>
      {showCustomer ? <CustomerForm hideForm={hideForm} /> : null}
    </div>
  )
}

export default Customers
