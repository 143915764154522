import { createSlice } from '@reduxjs/toolkit'

import {
  fetchAllCriteria,
  saveCriteria,
  removeCriteria,
  getCriteria
} from '../services/Criteria.services'

type TInitialStateData = {
  loading: 'idle' | 'pending' | 'failed' | 'completed'
  error: null | string | undefined
  data: any
  totalData?: number
  navKeys?: any
  isNext?: boolean
  isPrev?: boolean
}

type TInitialState = {
  getall: TInitialStateData
  addnew: TInitialStateData
  getdata: TInitialStateData
  search: TInitialStateData
  delete: TInitialStateData
  isEditMode: boolean
}
const initialStateData: TInitialStateData = {
  loading: 'idle',
  error: null,
  data: [],
  totalData: 0
}

const initialState: TInitialState = {
  getall: initialStateData,
  addnew: initialStateData,
  getdata: initialStateData,
  search: initialStateData,
  delete: initialStateData,
  isEditMode: false
}

const criteria = createSlice({
  name: 'criteria',
  initialState,
  reducers: {
    reset: (state, { payload }) => {
      switch (payload) {
        case 'addnew':
          state.addnew = initialStateData

          break
        case 'getdata':
          state.getdata = initialStateData
          state.isEditMode = false

          break
        case 'search':
          state.search = initialStateData

          break
        case 'delete':
          state.delete = initialStateData
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCriteria.pending, (state) => {
        state.getall.loading = 'pending'
        state.getall.error = null
        state.getall.data = []
      })
      .addCase(fetchAllCriteria.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.getall.loading = 'completed'
          state.getall.data = payload.data
          state.getall.totalData = payload.total
          state.getall.error = null

          if (payload.key && payload.type === 'next') {
            state.getall.navKeys.push(payload.key)
            state.getall.isNext = true
            state.getall.isPrev = true
          } else if (payload.key && payload.type === 'prev') {
            state.getall.navKeys.pop()
            state.getall.isNext = true
            state.getall.isPrev = true
          } else if (!payload.key && payload.type === 'next') {
            state.getall.isNext = false
            state.getall.isPrev = true
          } else {
            state.getall.navKeys = []
            state.getall.isPrev = false

            if (payload.key === undefined) {
              state.getall.isNext = false
            } else {
              state.getall.isNext = true
              state.getall.navKeys.push(payload.key)
            }

            state.getall.totalData = payload.total
          }
        }
      })
      .addCase(fetchAllCriteria.rejected, (state, { payload }) => {
        state.getall.loading = 'failed'
        state.getall.data = []
        state.getall.error = payload
      })

    builder
      .addCase(saveCriteria.pending, (state) => {
        state.addnew.loading = 'pending'
        state.addnew.error = null
        state.addnew.data = ''
      })
      .addCase(saveCriteria.fulfilled, (state) => {
        state.addnew.loading = 'completed'
        state.addnew.error = null
        state.addnew.data = ''
      })
      .addCase(saveCriteria.rejected, (state, { payload }) => {
        state.addnew.loading = 'failed'
        state.addnew.error = payload
        state.addnew.data = ''
      })

    builder
      .addCase(removeCriteria.pending, (state) => {
        state.delete.loading = 'pending'
        state.delete.error = null
        state.delete.data = ''
      })
      .addCase(removeCriteria.fulfilled, (state) => {
        state.delete.loading = 'completed'
        state.delete.error = null
        state.delete.data = ''
      })
      .addCase(removeCriteria.rejected, (state, { payload }) => {
        state.delete.loading = 'failed'
        state.delete.error = payload
        state.delete.data = ''
      })

    builder
      .addCase(getCriteria.pending, (state) => {
        state.getdata.loading = 'pending'
        state.getdata.error = null
        state.getdata.data = []
      })
      .addCase(getCriteria.fulfilled, (state, { payload }) => {
        if (payload.data) {
          state.getdata.loading = 'completed'
          state.getdata.data = payload.data
          state.getdata.error = null

          if (payload.editMode === true) {
            state.isEditMode = true
          }
        }
      })
      .addCase(getCriteria.rejected, (state, { payload }) => {
        state.getdata.loading = 'failed'
        state.getdata.data = []
        state.getdata.error = payload
      })
  }
})

export const { reset } = criteria.actions

export default criteria.reducer
